import { Button } from "@pankod/refine-antd";

interface Props {
  content: Array<Content> | Content
}

interface Content {
  text: string;
  onClick: () => void;
  ModalComponent: any;
}

const CreateUploadModals: React.FC<Props> = ({ content }) => {
  const hasManyContents = Array.isArray(content);
  
  const renderUploadModal = ({ text, onClick, ModalComponent, ...props }: Content, index?: number) => (
    <div className="flex flex-row gap-4" key={index}>
      <Button onClick={onClick}>
        {text}
      </Button>
      <ModalComponent {...props} />
    </div>
  );

  return (
    <div className="flex flex-row gap-4">
      {hasManyContents ?
       content.map((content: Content, index: number) => renderUploadModal(content, index)) :
       renderUploadModal(content)
      }
    </div>
  );
}

export default CreateUploadModals;