import { useState } from "react";
import {
  useCreate,
  useDelete,
  useModal,
} from "@pankod/refine-core";
import {
  Button,
  DatePicker,
  Form,
  Icons,
  Input,
  Popconfirm,
  Table,
  TextField,
} from "@pankod/refine-antd";

import CreateQuestionModal from "components/assessments/createQuestionModal";
import EditQuestionModal from "components/assessments/editQuestionModal";
import { IAssessmentShow, IAssessmentQuestion } from "interfaces";

interface Props {
  assessment: IAssessmentShow;
  onUpdate: () => void;
}

const CreateButton: React.FC<Props> = ({ assessment, onUpdate }) => {
  const modal = useModal();

  return (
    <div className="flex flex-row gap-4">
      <Button onClick={modal.show}>
        Criar questão
      </Button>

      <CreateQuestionModal modal={modal} assessment={assessment} onUpdate={onUpdate} />
    </div>
  )
}

const Actions = ({ question, onUpdate }: { question: IAssessmentQuestion, onUpdate: () => void }) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { mutate } = useDelete<IAssessmentQuestion>();
  const modal = useModal();

  const showPopconfirm = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    mutate({
      resource: "assessment_questions",
      id: question.id,
    }, {
      onSuccess: () => {
        setVisible(false);
        setConfirmLoading(false);
        onUpdate();
      },
      onError: () => {
        setConfirmLoading(false);
      }
    })
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div className="flex flex-row gap-2">
      <Button className="!flex items-center" onClick={modal.show}>
        <Icons.EditOutlined />Editar
      </Button>
      <Popconfirm
        title="Deletar"
        icon={<Icons.QuestionCircleOutlined style={{ color: 'red' }} />}
        visible={visible}
        onConfirm={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ loading: confirmLoading, danger: true }}
      >
        <Button className="!flex items-center" danger onClick={showPopconfirm}>
          <Icons.CloseCircleOutlined />Deletar
        </Button>
      </Popconfirm>

      <EditQuestionModal modal={modal} question={question} onUpdate={onUpdate} />
    </div>
  )
}

const QuestionTable: React.FC<Props> = ({ assessment, onUpdate }) => (
  <Table
    dataSource={assessment.questions}
    rowKey="id"
    bordered
    pagination={false}
  >
    <Table.Column
      dataIndex="statement"
      key="statement"
      title="Enunciado"
      render={(value) => <TextField value={value} />}
    />
    <Table.Column<IAssessmentQuestion>
      dataIndex="actions"
      key="actions"
      title="Ações"
      className="w-4"
      render={(_, question) => <Actions question={question} onUpdate={onUpdate} />}
    />
  </Table>
)

const SectionTableWrapper: React.FC<Props> = ({ assessment, onUpdate }) => (
  <div className="flex gap-4 flex-col">
    <QuestionTable assessment={assessment} onUpdate={onUpdate} />
    <CreateButton assessment={assessment} onUpdate={onUpdate} />
  </div>
)

export default SectionTableWrapper;
