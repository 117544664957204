import { IUser } from "interfaces";
import api from 'requests/api';

interface Props {
  user: IUser;
  email: string;
}

export const updateEmail = async({ user, email }: Props): Promise<IUser> => {
  const { data } = await api.post(`/admin/users/${user.id}/update_email`, { email });

  return data;
}
