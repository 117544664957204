import { IOrder, IAdmin } from "interfaces";

export const STATUS_TRANSLATIONS = new Map([
  ["requested", "Aberto"],
  ["approved", "Aprovado"],
  ["rejected", "Rejeitado"],
  ["processing", "Processando"],
  ["processed", "Processado"],
]);

const REFUND_ROLES: IAdmin["roles"] = ["master"];

export const translateStatus = (status: IOrder["status"]): string => (
  STATUS_TRANSLATIONS.get(status) || status
)

export const allow = ({ roles }: { roles: IAdmin["roles"] }) => (
  REFUND_ROLES.some((role) => (
    roles.includes(role)
  ))
);
