import { QueryObserverResult } from "react-query";
import {
  IResourceComponentsProps,
  GetOneResponse,
  usePermissions,
} from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  Select,
  Typography,
  useForm,
} from "@pankod/refine-antd";

import { IAdmin } from "interfaces";
import { ROLE_TRANSLATIONS, allowedRoles, allowPasswordChange } from "services/admin";

const { Text, Title } = Typography;

export const AdminEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IAdmin>();
  const { data, isLoading } = queryResult as QueryObserverResult<GetOneResponse<IAdmin>>;
  const { data: roles } = usePermissions();

  if (!data || !roles) {
    return null;
  }

  const { data: record } = data as GetOneResponse<IAdmin>;
  const allowed = allowedRoles({ roles });

  return (
    <Edit isLoading={isLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Title level={5}>Username</Title>
        <Text>{record.name}</Text>
        <Title level={5}>Nome</Title>
        <Text>{record.username}</Text>
        <Title level={5} />
        {allowPasswordChange({ roles, record }) && (
          <Form.Item
            label="Alterar senha"
            name="password"
          >
            <Input.Password placeholder="senha" />
          </Form.Item>
        )}
        <Title />
        <Form.Item
            label="Funções"
            name="roles"
        >
          <Select mode="multiple">
          {Array.from(ROLE_TRANSLATIONS).map(([key, value]) => (
            <Select.Option
              key={key}
              value={key}
              disabled={!allowed.includes(key)}
            >
              {value}
            </Select.Option>
          ))}
          </Select>
        </Form.Item>
      </Form>
    </Edit>
  );
};
