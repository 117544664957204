import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  ReadyPage,
  ErrorComponent,
  Icons,
} from "@pankod/refine-antd";
import "styles/antd.less";
import routerProvider from "@pankod/refine-react-router-v6";
import accessProvider from "./accessProvider";
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import { AdminList, AdminCreate, AdminEdit } from "pages/admins";
import { AssessmentList, AssessmentCreate, AssessmentEdit } from "pages/assessments";
import { CourseList, CourseEdit, CourseCreate } from "pages/courses";
import { InstructorList, InstructorEdit, InstructorCreate } from "pages/instructors";
import { OrderList, OrderShow } from "pages/orders";
import { RefundList, RefundShow } from "pages/refunds";
import { TagList, TagEdit, TagCreate } from "pages/tags";
import { UserList, UserShow } from "pages/users";
import { LoginPage } from "pages/login";
import api from 'requests/api';
import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from "components/layout";

function App() {
  return (
    <Refine
      notificationProvider={notificationProvider}
      ReadyPage={ReadyPage}
      catchAll={<ErrorComponent />}
      accessControlProvider={accessProvider}
      routerProvider={routerProvider}
      dataProvider={dataProvider(`${process.env.REACT_APP_API_HOST}/admin`, api)}
      authProvider={authProvider}
      LoginPage={LoginPage}
      resources={[
        {
          name: "users",
          list: UserList,
          show: UserShow,
          icon: <Icons.UserOutlined />,
          options: {
            label: "Alunos",
          },
        },
        {
          name: "orders",
          list: OrderList,
          show: OrderShow,
          icon: <Icons.InboxOutlined />,
          options: {
            label: "Pedidos",
          },
        },
        {
          name: "order_refunds",
          list: RefundList,
          show: RefundShow,
          icon: <Icons.DollarCircleOutlined />,
          options: {
            label: "Reembolsos",
          },
        },
        {
          name: "admins",
          list: AdminList,
          create: AdminCreate,
          edit: AdminEdit,
          icon: <Icons.IdcardOutlined />,
          options: {
            label: "Administrativo",
          },
        },
        {
          name: "courses",
          list: CourseList,
          create: CourseCreate,
          edit: CourseEdit,
          icon: <Icons.BookOutlined />,
          options: {
            label: "Cursos",
          },
        },
        {
          name: "assessments",
          list: AssessmentList,
          edit: AssessmentEdit,
          create: AssessmentCreate,
          icon: <Icons.CompassOutlined />,
          options: {
            label: "Avaliações",
          },
        },
        {
          name: "instructors",
          list: InstructorList,
          edit: InstructorEdit,
          create: InstructorCreate,
          icon: <Icons.CompassOutlined />,
          options: {
            label: "Instrutores",
          },
        },
        {
          name: "tags",
          icon: <Icons.TagOutlined />,
          list: TagList,
          edit: TagEdit,
          create: TagCreate,
          options: {
            label: "Tags",
          },
        },
      ]}
      Title={Title}
      Header={Header}
      Sider={Sider}
      Footer={Footer}
      Layout={Layout}
      OffLayoutArea={OffLayoutArea}
    />
  );
}

export default App;
