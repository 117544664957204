import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Typography,
} from "antd";
import { useLogin } from "@pankod/refine-core";

import {
    layoutStyles,
    containerStyles,
    titleStyles,
    imageContainer,
} from "./styles";

import Logotype from "assets/logotype";

const { Title } = Typography;
export interface ILoginForm {
    username: string;
    password: string;
    remember: boolean;
}

/**
 * **refine** has a default login page form which is served on `/login` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/api-references/components/refine-config#loginpage} for more details.
 */
export const LoginPage: React.FC = () => {
    const [form] = Form.useForm<ILoginForm>();
    const { mutate: login, isLoading } = useLogin<ILoginForm>();

    const CardTitle = (
        <Title level={3} style={titleStyles}>
            Login
        </Title>
    );

  return (
    <Layout style={layoutStyles}>
      <Row
        justify="center"
        align="middle"
        style={{
            height: "100vh",
        }}
      >
        <Col xs={22}>
          <div style={containerStyles}>
            <div style={imageContainer}>
              <Logotype style={{ color: "white" }} />
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
            <Form<ILoginForm>
              layout="vertical"
              form={form}
              onFinish={(values) => {
                  login(values);
              }}
              requiredMark={false}
              initialValues={{
                  remember: false,
              }}
            >
              <Form.Item
                name="username"
                label="Usuário"
                rules={[{ required: true }]}
              >
                <Input
                  size="large"
                  placeholder="usuario"
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Senha"
                rules={[{ required: true }]}
                style={{ marginBottom: "12px" }}
              >
                <Input
                  type="password"
                  placeholder="●●●●●●●●"
                  size="large"
                />
              </Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={isLoading}
                block
                style={{ backgroundColor: "#0075C0" }}
              >
                Entrar
              </Button>
            </Form>
          </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};
