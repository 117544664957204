import { IResourceComponentsProps, CrudFilters, HttpError } from "@pankod/refine-core";
import {
  Col,
  Button,
  Form,
  List,
  Row,
  Select,
  ShowButton,
  Space,
  Table,
  TagField,
  TextField,
  useTable,
} from "@pankod/refine-antd";

import { IOrderRefund } from "interfaces";
import { formatDate } from "services/date";
import { translateStatus, STATUS_TRANSLATIONS } from "services/refund";

interface IFilter {
    status: IOrderRefund["status"];
}

export const RefundList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<IOrderRefund, HttpError, IFilter>({
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { status } = params;

      filters.push({
        field: "status",
        operator: "eq",
        value: status,
      });

      return filters;
    },
    initialFilter: [
        {
            field: "status",
            operator: "eq",
            value: "requested",
        },
    ],

  });

  return (
    <Row gutter={[16, 16]}>
      <Col lg={6} xs={24}>
        <Form layout="vertical" {...searchFormProps}>
          <Form.Item label="Status" name="status">
            <Select defaultValue="requested">
              <Select.Option value={null}>Todos</Select.Option>
            {Array.from(STATUS_TRANSLATIONS).map(([key, value]) => (
              <Select.Option value={key}>{value}</Select.Option>
            ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Filter
            </Button>
          </Form.Item>
        </Form>
      </Col>
      <Col lg={18} xs={24}>

        <List>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="order"
              key="code"
              title="#"
              render={({ code }) => <TextField value={code} />}
            />
            <Table.Column
              dataIndex="status"
              key="status"
              title="Status"
              render={(status) => <TagField value={translateStatus(status)} />}
            />
            <Table.Column
              dataIndex="created_at"
              key="created_at"
              title="Data de Criação"
              render={(createdAt) => <TextField value={formatDate(createdAt)} />}
            />
            <Table.Column
              dataIndex="order"
              key="item"
              title="Curso"
              render={({ item }) => <TextField value={item.name} />}
            />
            <Table.Column<IOrderRefund>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <ShowButton hideText size="large" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>

  );
};
