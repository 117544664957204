import { useState } from "react";
import {
  useCreate,
  useDelete,
  useModal,
} from "@pankod/refine-core";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Icons,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Table,
  TagField,
  TextField,
  Typography,
} from "@pankod/refine-antd";

import EditModal from "./editModal";
import { ICourseShow, IItemShow, IOffer } from "interfaces";
import { formatDate } from "services/date";
import { DAYS_TRANSLATIONS, translateDays } from "services/item";
import { formatMoney } from "services/money";

const { Title } = Typography;

interface Props {
  course: ICourseShow;
  item: IItemShow;
  onUpdate: () => void;
}

interface FormProps {
  item: IItemShow;
  onUpdate: () => void;
}

interface ActionProps {
  offer: IOffer;
  onUpdate: () => void;
}

const Actions: React.FC<ActionProps> = ({ offer, onUpdate }) => {
  const modal = useModal();

  return (
    <>
      <Button className="!flex items-center" onClick={modal.show}>
        <Icons.EditOutlined />
      </Button>
      <EditModal offer={offer} onUpdate={onUpdate} modal={modal} />
    </>
  )
}

const OfferTable: React.FC<Props> = ({ course, item, onUpdate }) => (
  <Table
    dataSource={item.offers}
    rowKey="id"
    bordered
    pagination={false}
  >
    <Table.Column
      dataIndex="id"
      key="id"
      title="ID"
      render={(value) => <TextField value={value} />}
    />
    <Table.Column
      dataIndex="name"
      key="name"
      title="Nome"
      render={(value) => <TextField value={value} />}
    />
    <Table.Column
      dataIndex="price"
      key="price"
      title="Preço"
      render={(value) => <TextField value={formatMoney(value)} />}
    />
    <Table.Column
      dataIndex="reference_price"
      key="reference_price"
      title="Preço de Referência"
      render={(value) => <TextField value={formatMoney(value)} />}
    />
    <Table.Column
      dataIndex="active"
      key="active"
      title="Status"
      render={(value) => (
        value ? (
          <TagField value="ativo" color="success"/>
        ) : (
          <TagField value="inativo"/>
        )
      )}
    />
    <Table.Column<IOffer>
      dataIndex="actions"
      key="actions"
      title="Ações"
      render={(_, offer) => <Actions offer={offer} onUpdate={onUpdate} />}
    />
  </Table>
);

const Wrapper: React.FC<Props> = ({ course, item, onUpdate }) => (
  <>
    <OfferTable course={course} item={item} onUpdate={onUpdate} />
  </>
)

export default Wrapper;
