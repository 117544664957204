import { useModal } from "@pankod/refine-core";
import AddAssessmentModal from "components/courses/addAssessmentModal";
import ExternalHtmlModal from "components/courses/externalHtmlModal";
import UploadDocumentModal from "components/courses/uploadDocumentModal";
import UploadVideoModal from "components/courses/uploadVideoModal";
import CreateUploadModals from 'components/courses/uploads/create'

interface Props {
  parent: any;
  onUpdate: () => void;
  type: 'contents' | 'resources';
  parentKind: string;
  text?: string;
}

const CreateUploadModalsGroup: React.FC<Props> = ({ parent, parentKind, type, onUpdate, text = "conteúdo" }: any) => {
  const uploadVideoModal = useModal();
  const uploadDocumentModal = useModal();
  const externalHtmlModal = useModal();
  const addAssessmentModal = useModal();

  const uploadButtons = [
    {
      text: `Subir ${text} em vídeo`,
      onClick: uploadVideoModal.show,
      modal: uploadVideoModal,
      ModalComponent: UploadVideoModal,
      parent: parent,
      parentKind,
      type,
      onUpdate
    },
    {
      text: `Subir ${text} em pdf`,
      onClick: uploadDocumentModal.show,
      modal: uploadDocumentModal,
      ModalComponent: UploadDocumentModal,
      parent: parent,
      parentKind,
      type,
      onUpdate
    },
    {
      text: `Subir ${text} em html`,
      onClick: externalHtmlModal.show,
      modal: externalHtmlModal,
      ModalComponent: ExternalHtmlModal,
      parent: parent,
      parentKind,
      type,
      onUpdate
    },
    {
      text: `Adicionar avaliação`,
      onClick: addAssessmentModal.show,
      modal: addAssessmentModal,
      ModalComponent: AddAssessmentModal,
      parent: parent,
      parentKind,
      type,
      onUpdate
    }
  ]

  return (
    <CreateUploadModals content={uploadButtons} />
  )
};

export default CreateUploadModalsGroup;
