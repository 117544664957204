import { IResourceComponentsProps, CrudFilters, HttpError } from "@pankod/refine-core";
import {
  Button,
  Col,
  Form,
  Input,
  List,
  Row,
  ShowButton,
  Space,
  Table,
  TextField,
  useTable,
} from "@pankod/refine-antd";

import { IUser } from "interfaces";
import { formatDate } from "services/date";

interface IFilter {
  email: string;
  name: string;
}

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<IUser, HttpError, IFilter>({
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { email, name } = params;

      filters.push(
        {
          field: "email",
          operator: "eq",
          value: email,
        },
        {
          field: "name",
          operator: "eq",
          value: name,
        }
      );

      return filters;
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col lg={6} xs={24}>
        <Form layout="vertical" {...searchFormProps}>
          <Form.Item label="Email" name="email">
              <Input
                  placeholder="email@domain.com"
              />
          </Form.Item>
          <Form.Item label="Nome" name="name">
              <Input
                  placeholder="João Santos"
              />
          </Form.Item>
          <Form.Item>
              <Button htmlType="submit" type="primary">
                  Filter
              </Button>
          </Form.Item>
        </Form>
      </Col>
      <Col lg={18} xs={24}>

        <List>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="email"
              key="email"
              title="Email"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="name"
              key="name"
              title="Nome"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="created_at"
              key="created_at"
              title="Cadastro"
              render={(value) => <TextField value={formatDate(value)} />}
            />
            <Table.Column<IUser>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <ShowButton hideText size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>

  );
};
