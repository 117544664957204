import {
  Icons,
  TagField
} from "@pankod/refine-antd";

import { IOption } from "interfaces";

const OptionTag = ({ option }: { option: IOption }) => {
  const { provider, status } = option;

  const mapping = {
    enabled: {
      icon: <Icons.CheckCircleOutlined />,
      color: "success",
    },
    processing: {
      icon: <Icons.SyncOutlined spin />,
      color: "purple",
    },
    disabled: {
      icon: <Icons.MinusCircleOutlined />,
      color: "default",
    },
    error: {
      icon: <Icons.CloseCircleOutlined />,
      color: "error",
    },
  }

  const props = mapping[status];

  return (
    <TagField
      className="!flex items-center w-fit"
      value={provider}
      {...props}
    />
  )
}

export default OptionTag;