import { useState } from "react";
import {
  IResourceComponentsProps,
} from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  Typography,
  useForm,
} from "@pankod/refine-antd";

import AssetUpload from "components/assetUpload";
import { IInstructorShow } from "interfaces";

const { Title } = Typography;

export const InstructorCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IInstructorShow>();
  const [images, setImages] = useState({ avatar: undefined });

  const onChangeImages = async (params: any) => {
    setImages({ ...images, ...params });
  }

  const onFinish = (values: any) => {
    formProps.onFinish!({ ...values, images })
  }

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Biografia"
          name="biography"
        >
          <Input />
        </Form.Item>

        <Title level={4}>Images</Title>
        <Form.Item
          label="Avatar"
          name={["images", "avatar"]}
        >
          <AssetUpload
            accept="image/png, image/jpeg"
            type="instructor"
            onChange={(url) => onChangeImages({ avatar: url })}
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
