import { AuthProvider } from "@pankod/refine-core";

import { login } from 'requests/auth';
import { setSession, clearSession, getToken, getRefreshToken } from 'services/auth';
import { getRoles } from 'services/roles';

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    try {
      const session = await login({ username, password });
      setSession(session);
      return Promise.resolve();
    } catch {
      return Promise.reject(new Error("Email e/ou senha inválidos"));
    }
  },
  logout: () => {
    clearSession();
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    if (getToken() || getRefreshToken()) {
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: getRoles,
  getUserIdentity: async () => {
    const authed = getToken() || getRefreshToken();
    if (!authed) {
      return Promise.reject();
    }
    return getRoles();
  },
};

export default authProvider;
