import { IItem } from "interfaces";

export const DAYS_TRANSLATIONS = new Map([
  [null, "Vitalício"],
  [365, "12 meses"],
  [183, "6 meses"],
]);

export const translateDays = (time_in_days: IItem["time_in_days"]): string => (
  DAYS_TRANSLATIONS.get(time_in_days) || time_in_days!.toString()
)
