import { useState } from "react";
import { useCreate, useModalReturnType, useList } from "@pankod/refine-core";
import {
  Button,
  Form,
  Icons,
  Input,
  Modal,
  Progress,
  Select,
} from "@pankod/refine-antd";
import axios from "axios";
import convertMutateData from "components/courses/uploads/utils";
import { ITopicShow, IContent, IAssessment } from "interfaces";

interface Props {
  modal: useModalReturnType;
  parent: any;
  parentKind: string;
  type: string;
  onUpdate: () => void;
}

const AddAssessmentModal: React.FC<Props> = ({ modal, parent, parentKind, type, onUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { mutate } = useCreate<IContent>();
  const { data: assessmentData } = useList<IAssessment>({
    resource: "assessments",
    config: { pagination: { pageSize: 100 } }
  });

  const assessments = assessmentData?.data || [];

  const onClose = () => {
    form.resetFields();
    setLoading(false);
    modal.close();
  }

  const onSubmit = async () => {
    setLoading(true);

    const { name, assessmentId } = await form.validateFields();

    try {
      await createContent({ name, assessmentId });

      onUpdate();
      onClose();
    } catch {

    } finally {
      setLoading(false);
    }
  }

  const createContent = ({ name, assessmentId }: { name: string, assessmentId: string }) => {
    const items = parent[type] || [];

    const data = {
      name,
      kind: 'assessment',
      reference: assessmentId,
      order: items.length + 1,
      parent_type: parentKind,
      parent_id: parent.id
    }

    const mutateObject = convertMutateData(type, data);

    return new Promise<IContent>((resolve, reject) => {
      mutate(mutateObject, {
        onSuccess: ({ data }) => resolve(data),
        onError: (error) => reject(error),
      })
    })
  }

  return (
    <Modal
      title="Adicionar avaliação"
      visible={modal.visible}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Adicionar
        </Button>
      ]}
    >
      <Form form={form} layout="vertical" preserve={false}>
        <Form.Item
          label="Nome"
          name="name"
          required
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Avaliação"
          name="assessmentId"
          required
        >
          <Select
            showSearch
            optionFilterProp="children"
          >
            {assessments.map(({ id, name }) => (
              <Select.Option key={id} value={id}>{name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
};

export default AddAssessmentModal;
