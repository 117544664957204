import { useState } from "react";
import { useCreate, useModalReturnType, useList } from "@pankod/refine-core";
import {
  Button,
  Checkbox,
  Form,
  Icons,
  Input,
  Modal,
  Radio,
  Select,
  Space,
} from "@pankod/refine-antd";
import axios from "axios";

import { IAssessmentShow, IAssessmentQuestion } from "interfaces";

interface Props {
  modal: useModalReturnType;
  assessment: IAssessmentShow;
  onUpdate: () => void;
}

const CreateQuestionModal: React.FC<Props> = ({ modal, assessment, onUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { mutate } = useCreate<IAssessmentQuestion>();
  const [checkedAlternative, setCheckedAlternative] = useState<null | number>(null);

  const initialValues = {
    statement: "",
    alternatives: [],
  };

  const onClose = () => {
    form.resetFields();
    setLoading(false);
    modal.close();
  }

  const onSubmit = async () => {
    setLoading(true);

    try {
      const { statement, alternatives } = await form.validateFields();

      await createQuestion({ statement, alternatives });
      onUpdate();
      onClose();
    } catch {
    } finally {
      setLoading(false);
    }
  }

  const createQuestion = ({ statement, alternatives }: { statement: string, alternatives: { statement: string, correct: boolean }[] }) => (
    new Promise<IAssessmentQuestion>((resolve, reject) => {
      mutate({
        resource: "assessment_questions",
        values: { statement, alternatives, assessment_id: assessment.id }
      }, {
        onSuccess: ({ data }) => resolve(data),
        onError: (error) => reject(error),
      });
    })
  );

  return (
    <Modal
      title="Criar questão"
      visible={modal.visible}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Criar
        </Button>
      ]}
    >
      <Form form={form} initialValues={initialValues} layout="vertical" preserve={false}>
        <Form.Item
          label="Enunciado"
          name="statement"
          rules={[{ required: true}]}
        >
          <Input.TextArea rows={3} />
        </Form.Item>

        <Form.List
          name="alternatives"
          rules={[
            {
              validator: async (_, alternatives) => {
                const corrects = alternatives.filter((alternative: any) => (
                  alternative?.correct
                ));

                if (corrects.length !== 1) {
                  return Promise.reject();
                }
              },
              message: "'alternatives.correct' required (1)",
            },
          ]}
        >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(field => (
              <div key={field.key} className="flex gap-4 w-full items-center">
                <Form.Item
                  name={[field.name, 'correct']}
                  valuePropName="checked"
                  initialValue={fields.length === 1}
                >
                  <Checkbox />
                </Form.Item>
                <Form.Item
                  className="grow"
                  name={[field.name, 'statement']}
                  rules={[{ required: true}]}
                >
                  <Input.TextArea
                    rows={1}
                  />
                </Form.Item>
                <Icons.MinusCircleOutlined className="mb-4" onClick={() => remove(field.name)} />
              </div>
            ))}
            <Form.ErrorList errors={errors} className="mb-4" />
            <Button type="dashed" className="!flex items-center justify-center" onClick={() => add()} block>
              <Icons.PlusOutlined />
              Alternativa
            </Button>
          </>
        )}
        </Form.List>
      </Form>
    </Modal>
  )
};

export default CreateQuestionModal;
