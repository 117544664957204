import { IResourceComponentsProps, CrudFilters, HttpError } from "@pankod/refine-core";
import {
  Button,
  Col,
  EditButton,
  Form,
  Input,
  List,
  Table,
  TagField,
  TextField,
  Row,
  Select,
  Space,
  useTable,
} from "@pankod/refine-antd";

import { IAdmin } from "interfaces";
import { ROLE_TRANSLATIONS } from "services/admin";

interface IFilter {
  username: string;
  name: string;
  role: string;
}

export const AdminList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<IAdmin, HttpError, IFilter>({
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { username, name, role } = params;

      filters.push(
        {
          field: "username",
          operator: "eq",
          value: username,
        },
        {
          field: "name",
          operator: "eq",
          value: name,
        },
        {
          field: "role",
          operator: "eq",
          value: role,
        },
      );

      return filters;
    },
    initialFilter: [
      {
        field: "role",
        operator: "eq",
        value: null,
      },
    ],
  });

  return (
    <Row gutter={[16, 16]}>
      <Col lg={6} xs={24}>
        <Form layout="vertical" {...searchFormProps}>
          <Form.Item label="Name" name="name">
              <Input
                  placeholder="José Aldo"
              />
          </Form.Item>
          <Form.Item label="Username" name="username">
              <Input
                  placeholder="josealdo"
              />
          </Form.Item>
          <Form.Item label="Função" name="role">
              <Select>
                <Select.Option value={null}>Todos</Select.Option>
                {Array.from(ROLE_TRANSLATIONS).map(([key, value]) => (
                  <Select.Option key={key} value={key}>{value}</Select.Option>
                ))}
              </Select>
          </Form.Item>
          <Form.Item>
              <Button htmlType="submit" type="primary">
                  Filter
              </Button>
          </Form.Item>
        </Form>
      </Col>
      <Col lg={18} xs={24}>

        <List>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="name"
              key="name"
              title="Nome"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="username"
              key="username"
              title="Username"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="roles"
              key="roles"
              title="Funções"
              render={(values: IAdmin["roles"]) => (
                values.map((value) => <TagField value={value} />
              ))}
            />
            <Table.Column<IAdmin>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton hideText size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>

  );
};
