import { useState } from "react";
import {
  useCreate,
  useDelete,
} from "@pankod/refine-core";
import {
  Button,
  DatePicker,
  Form,
  Icons,
  Input,
  Popconfirm,
  Table,
  TextField,
} from "@pankod/refine-antd";

import ResourceTable from "components/courses/resourceTable";
import TopicTable from "components/courses/topicTable";
import { ICourseShow, ISection, ISectionShow } from "interfaces";
import { formatDate } from "services/date";

interface Props {
  course: ICourseShow;
  onUpdate: () => void;
}

const CreateForm: React.FC<Props> = ({ course, onUpdate }) => {
  const [form] = Form.useForm();
  const { mutate } = useCreate<ISectionShow>();

  const onFinish = ({ data }: { data: ISection}) => {

    mutate({
      resource: "sections",
      values: {
        ...data,
        course_id: course.id,
        order: course.sections.length + 1
      }
    }, { onSuccess: onUpdate });
  };

  return (
    <Form form={form} onFinish={(values) => onFinish({ data: values })}>
      <div className="flex gap-4 flex-row">
        <Form.Item
          name="name"
          required
        >
          <Input placeholder="Nome"/>
        </Form.Item>

        <Form.Item
          name="available_at"
        >
          <DatePicker
            showTime
            allowClear
            placeholder="Disponível em"
          />
        </Form.Item>

        <Button onClick={() => form.submit()}>
          Criar módulo
        </Button>
      </div>
    </Form>
  )
}

const Actions = ({ section, onUpdate }: { section: ISectionShow, onUpdate: () => void }) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { mutate } = useDelete<ISection>();

  const showPopconfirm = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    mutate({
      resource: "sections",
      id: section.id,
    }, {
      onSuccess: () => {
        setVisible(false);
        setConfirmLoading(false);
        onUpdate();
      },
      onError: () => {
        setConfirmLoading(false);
      }
    })
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Popconfirm
      title="Deletar"
      icon={<Icons.QuestionCircleOutlined style={{ color: 'red' }} />}
      visible={visible}
      onConfirm={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ loading: confirmLoading, danger: true }}
    >
      <Button className="!flex items-center" danger onClick={showPopconfirm}>
        <Icons.CloseCircleOutlined />Deletar
      </Button>
    </Popconfirm>
  )
}

const SectionTable: React.FC<Props> = ({ course, onUpdate }) => {
  const expandedComponents = (record: any) => (
    <div className="ml-8">
      <TopicTable section={record} onUpdate={onUpdate} />
      <ResourceTable reference={record} referenceKind="section" onUpdate={onUpdate} />
    </div>
  )

  return (
    <Table
      dataSource={course.sections}
      rowKey="id"
      bordered
      pagination={false}
      expandedRowRender={(record) => expandedComponents(record)}
    >
      <Table.Column<ISectionShow>
        dataIndex="order"
        sorter={(a, b) => a.order - b.order}
        defaultSortOrder="ascend"
        key="order"
        title="#"
        className="w-4"
        render={(value) => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="name"
        key="name"
        title="Módulo"
        render={(value) => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="topics"
        key="topics"
        title="Tópicos"
        className="w-4"
        render={({ length }) => <TextField value={length} />}
      />
      <Table.Column
        dataIndex="available_at"
        key="available_at"
        title="Disponível em"
        className="w-4"
        render={(availableAt) => (
          availableAt ? (
            <TextField value={formatDate(availableAt, 'lll')} />
          ) : (
            <TextField value="-" />
          )
        )}
      />
      <Table.Column<ISectionShow>
        dataIndex="actions"
        key="actions"
        title="Ações"
        className="w-4"
        render={(_, section) => <Actions section={section} onUpdate={onUpdate} />}
      />
    </Table>
)}

const SectionTableWrapper: React.FC<Props> = ({ course, onUpdate }) => (
  <div className="flex gap-4 flex-col">
    <SectionTable course={course} onUpdate={onUpdate} />
    <CreateForm course={course} onUpdate={onUpdate} />
  </div>
)

export default SectionTableWrapper;
