import {
  IResourceComponentsProps,
  usePermissions,
  useList,
} from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  Select,
  useForm,
} from "@pankod/refine-antd";

import { ICourse, IProductor } from "interfaces";

export const CourseCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<ICourse>();
  const { data: roles } = usePermissions();
  const { data: productorsData } = useList<IProductor>({ resource: "productors" });

  const productors = productorsData?.data || [];

  if (!roles) {
    return null;
  }

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          required
        >
          <Input placeholder="Nome do curso"/>
        </Form.Item>

        <div className="flex gap-4 flex-row">
          <Form.Item
            label="Slug"
            name="slug"
            required
          >
            <Input placeholder="slug-do-curso" />
          </Form.Item>

          <Form.Item
            label="Produtor"
            name="productor_id"
            required
          >
            <Select placeholder="Defina o produtor">
            {productors.map(({ id, name }) => (
              <Select.Option key={id} value={id}>{name}</Select.Option>
            ))}
            </Select>
          </Form.Item>
        </div>
      </Form>
    </Create>
  );
};
