import dayjs from "dayjs";
import 'dayjs/locale/pt-br';
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(localeData);
dayjs.extend(localizedFormat)
dayjs.locale('pt-BR');

export const formatDate = (dateString: string, format: string = 'LLL') => {
  return parseDate(dateString).format(format);
};

export const parseDate = (dateString: string | undefined) => {
  return dayjs(dateString).locale('pt-BR');
};
