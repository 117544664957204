import { useState } from "react";
import { useCreate, useModalReturnType, useList } from "@pankod/refine-core";
import {
  Button,
  Form,
  Icons,
  Input,
  InputNumber,
  Modal,
  Progress,
  Select,
  Switch,
  Typography,
  Upload,
  UploadFile,
} from "@pankod/refine-antd";
import axios from "axios";

import AssetUpload from "components/assetUpload";
import { IItem, IOffer, IGateway, IFiscalTemplate } from "interfaces";

const { Title } = Typography;

interface Props {
  modal: useModalReturnType;
  item: IItem;
  onUpdate: () => void;
}

interface ICreateParams {
  name: string;
  price: number;
  reference_price: number;
  active: boolean;
  images: {
    thumbnail: string | undefined;
  }
  benefits: {
    text: string;
  }[];
  fiscal_template_id: string;
  gateway_id: string;
}

const CreateOfferModal: React.FC<Props> = ({ modal, item, onUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { mutate } = useCreate<IOffer>();
  const { data: gatewaysData } = useList<IGateway>({
    resource: "gateways",
    config: { pagination: { pageSize: 100 } }
  });
  const { data: fiscalTemplatesData } = useList<IFiscalTemplate>({
    resource: "fiscal_templates",
    config: { pagination: { pageSize: 100 } }
  });

  const gateways = gatewaysData ? gatewaysData.data : [];
  const fiscalTemplates = fiscalTemplatesData ? fiscalTemplatesData.data : [];

  const onClose = () => {
    form.resetFields();
    setLoading(false);
    modal.close();
  }

  const onSubmit = async () => {
    setLoading(true);

    const formData = await form.validateFields();

    const data = {
      ...formData,
      benefits: formData.benefits.split("\n").map((benefit: string) => ({ text: benefit }))
    }

    try {
      await createOffer(data);

      onUpdate();
      onClose();
    } catch {

    } finally {
      setLoading(false);
    }
  }

  const createOffer = (data: ICreateParams) => (
    new Promise<IOffer>((resolve, reject) => {
      mutate({
        resource: "offers",
        values: { ...data, item_id: item.id }
      }, {
        onSuccess: ({ data }) => resolve(data),
        onError: (error) => reject(error),
      });
    })
  );

  return (
    <Modal
      title="Criar oferta"
      visible={modal.visible}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Criar
        </Button>
      ]}
    >
      <Form form={form} layout="vertical" preserve={false}>
        <Form.Item
          label="Nome"
          name="name"
          required
        >
          <Input />
        </Form.Item>

        <div className="flex flex-row gap-4">
          <Form.Item
            label="Status"
            name="active"
            valuePropName="checked"
            required
          >
            <Switch
              checkedChildren="Ativo"
              unCheckedChildren="Inativo"
            />
          </Form.Item>

          <Form.Item
            label="Preço"
            name="price"
            required
          >
            <InputNumber addonBefore="R$" precision={2} />
          </Form.Item>

          <Form.Item
            label="Preço de Referência"
            name="reference_price"
            required
          >
            <InputNumber addonBefore="R$" precision={2} />
          </Form.Item>
        </div>

        <Form.Item
          label="Benefícios (1 por linha)"
          name="benefits"
          required
        >
          <Input.TextArea rows={5} />
        </Form.Item>

        <div className="flex flex-row gap-4">
          <Form.Item
            className="grow"
            label="Gateway"
            name="gateway_id"
            required
          >
            <Select>
              {gateways.map(({ id, name }) => (
                <Select.Option key={id} value={id}>{name}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            className="grow"
            label="Nota Fiscal"
            name="fiscal_template_id"
            required
          >
            <Select>
              {fiscalTemplates.map(({ id, name }) => (
                <Select.Option key={id} value={id}>{name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>


        <Title level={4}>Images</Title>
        <Form.Item
          label="Thumbnail"
          name={["images", "thumbnail"]}
          required
        >
          <AssetUpload
            accept="image/png, image/jpeg"
            type="course"
          />
        </Form.Item>
      </Form>
    </Modal>
  )
};

export default CreateOfferModal;
