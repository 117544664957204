import { IResourceComponentsProps, useShow, useInvalidate } from "@pankod/refine-core";
import { Show } from "@pankod/refine-antd";

import OrderDetails from "components/orders/details";
import { IOrderRefund } from "interfaces";

export const RefundShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IOrderRefund>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const invalidate = useInvalidate();

  const onUpdate = () => {
    invalidate({
      resource: "order_refunds",
      invalidates: ["detail"],
      id: record?.id,
    });
  };

  return (
    <Show isLoading={isLoading}>
      {record && <OrderDetails order={record.order} onUpdate={onUpdate} />}
    </Show>
  );
};
