import { useEffect, useState } from "react";
import { useCreate } from "@pankod/refine-core";
import {
  Button,
  Icons,
  Progress,
  Upload,
  UploadFile,
} from "@pankod/refine-antd";
import axios from "axios";

type File = undefined | UploadFile;
type Preview = undefined | string;

interface Props {
  accept: string | undefined;
  type: 'instructor' | 'course';
  onChange?: (url: string | undefined) => void;
  src?: Preview;
}

interface Asset {
  url: string;
  upload_url: string;
}

const AssetUpload: React.FC<Props> = ({ accept, type, onChange, src }) => {
  const [file, setFile] = useState<File>(undefined);
  const [preview, setPreview] = useState<Preview>(src);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const { mutate } = useCreate<Asset>();

  const fileExtension = file ? file.name.split('.').pop() : undefined;
  const previewExtension = preview ? preview.split('.').pop() : undefined;

  const createAsset = () => (
    new Promise<Asset>((resolve, reject) => {
      mutate({
        resource: "assets",
        values: { type, extension: fileExtension }
      }, {
        onSuccess: ({ data }) => resolve(data),
        onError: (error) => reject(error),
      });
    })
  );

  const upload = async () => {
    try {
      const { url, upload_url } = await createAsset();

      setUploadPercentage(0);
      setIsUploading(true);

      await axios.put(upload_url, file, {
        onUploadProgress: ({ loaded, total }) => {
          setUploadPercentage(Math.round(100 * loaded / total));
        }
      });

      setPreview(url);
    } catch {
      setIsUploading(false);
    }

    setIsLoading(false);
  }

  const remove = () => {
    setPreview(undefined);
    setFile(undefined);
    setUploadPercentage(0);
    setIsUploading(false);
  }

  useEffect(() => {
    setIsLoading(true);

    if (file) {
      upload();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [file])

  useEffect(() => {
    setPreview(src);
  }, [src])

  useEffect(() => {
    onChange && onChange(preview)
    // eslint-disable-next-line
  }, [preview])

  return (
    <div className="flex flex-col gap-4 w-64">
      {preview && ['png', 'jpg', 'jpeg'].includes(previewExtension!) && (
        <img src={preview} className="w-64" alt="asset" />
      )}

      {preview && (
        <Button danger className="!flex items-center justify-center" disabled={isLoading} onClick={remove}>
          <Icons.CloseCircleOutlined />
          Remover
        </Button>
      )}

      <Upload
        className="w-full"
        maxCount={1}
        accept={accept}
        fileList={[]}
        onRemove={() => setFile(undefined)}
        beforeUpload={(newFile) => {
          setFile(newFile);

          return false;
        }}
      >
        <Button className="!flex items-center justify-center w-full" disabled={isLoading}>
          <Icons.UploadOutlined />
          Upload
        </Button>
      </Upload>
      {isUploading && <Progress percent={uploadPercentage} />}
    </div>
  )
}

export default AssetUpload;
