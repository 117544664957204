import { IAdmin } from "interfaces";
import { me } from "requests/admins";

const ROLES_KEY = "auth-roles";

export const getRoles = async (): Promise<IAdmin["roles"]> => {
  const persistedRoles = get();

  if (persistedRoles) {
    return JSON.parse(persistedRoles);
  }

  const { roles } = await me();

  set(roles);

  return roles;
};

export const clearRoles = () => {
  localStorage.removeItem(ROLES_KEY);
};

const get = () => (
  localStorage.getItem(ROLES_KEY)
);

const set = (roles: IAdmin["roles"]) => {
  localStorage.setItem(ROLES_KEY, JSON.stringify(roles));
};
