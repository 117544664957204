import {
  Button,
  Icons,
  Popconfirm,
  Table,
  TextField,
} from "@pankod/refine-antd";
import { IOption } from "interfaces";
import { useState } from "react";
import { useDelete } from "@pankod/refine-core";
import CreateUploadModalsGroup from 'components/courses/uploads/create_group';
import OptionTag from "components/courses/uploads/option_tag";

interface Props {
  onUpdate: () => void;
  reference: any;
  referenceKind: 'topic' | 'section' | 'course';
}

const Actions = ({ content, onUpdate }: { content: any, onUpdate: () => void }) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { mutate } = useDelete();

  const showPopconfirm = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    mutate({
      resource: "resources",
      id: content.id,
    }, {
      onSuccess: () => {
        setVisible(false);
        setConfirmLoading(false);
        onUpdate();
      },
      onError: () => {
        setConfirmLoading(false);
      }
    })
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Popconfirm
      title="Deletar"
      icon={<Icons.QuestionCircleOutlined style={{ color: 'red' }} />}
      visible={visible}
      onConfirm={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ loading: confirmLoading, danger: true }}
    >
      <Button className="!flex items-center" danger onClick={showPopconfirm}>
        <Icons.CloseCircleOutlined />Deletar
      </Button>
    </Popconfirm>
  )
}

const ResourceTable: React.FC<Props> = ({ reference, referenceKind, onUpdate }) => {
  const collumns = [
    {
      key: "order",
      title: "#",
      sorter: (a: any, b: any) => a.order - b.order
    },
    {
      key: "name",
      title: `Material Complementar`
    },
    {
      key: "kind",
      title: "Tipo"
    },
    {
      key: "options",
      title: "Opções",
      render: (values: any) => (values.map((option: IOption) => (<OptionTag option={option} />)))
    },
    {
      key: "actions",
      title: "Ações",
      render: (_: any, content: any) => <Actions content={content} onUpdate={onUpdate} />
    }
  ]

  return (
    <div className="flex gap-4 flex-col my-3">
      <TextField value={`Material Complementar de ${reference.name}`} />
      <Table
        dataSource={reference.resources}
        rowKey="id"
        bordered
        pagination={false}
      >
        {Array.isArray(collumns) &&
          collumns.map(({ key, ...props }, index: number) => {
            return (
              <Table.Column
                dataIndex={key}
                className="w-4"
                key={`${reference.id}-${index}`}
                render={(value: any) => <TextField value={value} />}
                {...props}
              />
            )
          })
        }
      </Table>
      <CreateUploadModalsGroup type="resources" text="material" parent={reference} parentKind={referenceKind} onUpdate={onUpdate} />
    </div>
  )
}

export default ResourceTable