const convertMutateData = (type: any, data: any) => {
  let responseObject: any = {};

  console.log(responseObject)

  responseObject.values = { 
    name: data.name,
    kind: data.kind,
    order: data.order,
    reference: data.reference
  }

  if(type === "contents" && data.parent_type === 'topic'){
    responseObject["resource"] = "contents"
    responseObject["values"]["topic_id"] = data.parent_id
    console.log(responseObject)
  } else if(type === "resources") {
    responseObject["resource"] = "resources"
    responseObject["values"]["parent_id"] = data.parent_id
    responseObject["values"]["parent_kind"] = data.parent_type
  }

  return responseObject;
}

export default convertMutateData;