import { IInvoice } from "interfaces";

export const STATUS_TRANSLATIONS = new Map([
  ["initial", "Aguardando emissão"],
  ["processing", "Em emissão"],
  ["issued", "Emitida"],
  ["cancelled", "cancelada"],
  ["error", "Erro"],
]);

export const translateStatus = (status: IInvoice["status"]): string => (
  STATUS_TRANSLATIONS.get(status) || status
)
