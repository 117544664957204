import {
  Button,
  Divider,
  Typography,
} from "@pankod/refine-antd";

import InoviceTable from "components/orders/invoiceTable";
import OrderTimeline from "components/orders/timeline";
import PaymentCard from "components/orders/paymentCard";
import RefundTable from "components/orders/refundTable";
import { IOrder } from "interfaces";
import { formatMoney } from "services/money";
import { translateStatus } from "services/order";

const { Title, Text } = Typography;

interface Props {
  order: IOrder;
  onUpdate: () => void;
}

interface FragmentProps {
  order: IOrder;
}

const head = (order: IOrder) => ([
  { title: "#", text: <b>{order.code}</b> },
  { title: "Status", text: <b>{translateStatus(order.status)}</b> },
  { title: "Valor Base", text: formatMoney(order.amount) },
  { title: "Desconto", text: formatMoney(order.discount_amount) },
  { title: "Valor Final", text: <b>{formatMoney(order.net_amount)}</b> },
]);

const user = (order: IOrder) => ([
  { title: "Nome", text: order.user.name },
  { title: "Email", text: order.user.email },
]);

const Head: React.FC<FragmentProps> = ({ order }) => (
  <>
  <div className="flex gap-4 flex-row flex-wrap">
    {head(order).map((item) => (
      <div key={item.title} className="flex flex-col" style={{ minWidth: "100px" }}>
        <Title level={5}>{item.title}</Title>
        <Text>{item.text}</Text>
      </div>
    ))}
  </div>
  </>
);

const User: React.FC<FragmentProps> = ({ order }) => (
  <>
    <div className="flex gap-4 flex-row flex-wrap">
      {user(order).map((item) => (
        <div key={item.title} className="flex flex-col" style={{ minWidth: "100px" }}>
          <Title level={5}>{item.title}</Title>
          <Text>{item.text}</Text>
        </div>
      ))}
    </div>
  </>
)

const Timeline: React.FC<FragmentProps> = ({ order }) => (
  <>
    <OrderTimeline order={order} />
  </>
)

const Payments: React.FC<FragmentProps> = ({ order }) => (
  <>
    <Title level={4}>Pagamentos</Title>
    <div className="flex flex-row">
    {order.payments.map((payment) => (
      <PaymentCard key={payment.id} payment={payment} />
    ))}
    </div>
  </>
);

const Refunds: React.FC<Props> = ({ order, onUpdate }) => (
  <>
    <Title level={4}>Reembolsos</Title>
    <RefundTable order={order} onUpdate={onUpdate} />
  </>
)

const Invoices: React.FC<FragmentProps> = ({ order }) => (
  <>
    <Title level={4}>Notas Fiscais</Title>
    <InoviceTable invoices={order.fiscal?.invoices || []} />
  </>
)

const Details: React.FC<Props> = ({ order, onUpdate }) => (
  <div className="flex gap-4 flex-col">
    <Head order={order} />
    <Divider />
    <User order={order} />
    <Divider />
    <Timeline order={order} />
    <Divider />
    <Payments order={order} />
    <Divider />
    <Invoices order={order} />
    <Divider />
    <Refunds order={order} onUpdate={onUpdate} />
  </div>
);

export default Details;
