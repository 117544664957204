import api from 'requests/api';

interface Props {
  invoiceId: string;
}

interface Download {
  url: string;
}

export const download = async({ invoiceId }: Props): Promise<Download> => {
  const { data } = await api.get(`/admin/invoices/${invoiceId}/download`);

  return data;
};
