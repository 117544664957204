import { useState } from "react";
import { useModalReturnType } from "@pankod/refine-core";
import {
  Button,
  Form,
  Input,
  Modal,
} from "@pankod/refine-antd";

import { IOrderRefundShallow } from "interfaces";
import { approve } from "requests/refunds";

interface Props {
  modal: useModalReturnType;
  refund: IOrderRefundShallow;
  onUpdate: () => void;
}

const ApproveRefundModal: React.FC<Props> = ({ modal, refund , onUpdate}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onSubmit = async ({ motive }: { motive: string }) => {
    setLoading(true);

    try {
      await approve({ refundId: refund.id, motive });
      modal.close();
      onUpdate();
    } catch {
    }
    setLoading(false);
  }

  const onClick = async () => {
    const { motive } = await form.validateFields();

    onSubmit({ motive });
  }

  return (
    <Modal
      title="Aprovar Reembolso"
      visible={modal.visible}
      onCancel={modal.close}
      footer={[
        <Button key="back" onClick={modal.close}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onClick}>
          Aprovar
        </Button>
      ]}
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item label="Motivo" name="motive" required>
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
      <p>Tem certeza que deseja aprovar esse reeembolso?</p>
      <p>Essa ação é irreversível.</p>
    </Modal>
  )
};

export default ApproveRefundModal;
