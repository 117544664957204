import { IAdmin } from "interfaces";

export const ROLE_TRANSLATIONS = new Map([
  ["support", "Suporte"],
  ["supervisor", "Supervisor"],
  ["master", "Master"],
]);

const ALLOWED_ROLES = {
  master: ["master", "supervisor", "support"],
  supervisor: ["support"],
  support: [],
};

export const allowedRoles = ({ roles }: { roles: IAdmin["roles"] }) => (
  roles.flatMap((role) => ALLOWED_ROLES[role])
)

export interface allowPasswordChangeProps {
  roles: IAdmin["roles"];
  record: IAdmin;
}

export const allowPasswordChange = ({ roles, record }: allowPasswordChangeProps) => {
  const allowed = allowedRoles({ roles });

  return record.roles.every((role) => (
    allowed.includes(role)
  ));
};
