import { IResourceComponentsProps, CrudFilters, HttpError } from "@pankod/refine-core";
import {
  Button,
  Col,
  Form,
  Input,
  List,
  Row,
  ShowButton,
  Space,
  Table,
  TagField,
  TextField,
  useTable,
} from "@pankod/refine-antd";

import { IOrder } from "interfaces";
import { formatDate } from "services/date";
import { translateStatus } from "services/order";

interface IOrderFilter {
  code: string;
  email: string;
  doc: string;
  name: string;
}

export const OrderList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<IOrder, HttpError, IOrderFilter>({
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { doc, email, code, name } = params;

      filters.push(
        {
          field: "code",
          operator: "eq",
          value: code,
        },
        {
          field: "document",
          operator: "eq",
          value: doc,
        },
        {
          field: "email",
          operator: "eq",
          value: email,
        },
        {
          field: "name",
          operator: "eq",
          value: name,
        }
      );

      return filters;
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col lg={6} xs={24}>
        <Form layout="vertical" {...searchFormProps}>
          <Form.Item label="#" name="code">
              <Input
                  placeholder="2ZBR01"
              />
          </Form.Item>
          <Form.Item label="CPF" name="doc">
              <Input
                  placeholder="000.000.000-00"
              />
          </Form.Item>
          <Form.Item label="Email" name="email">
              <Input
                  placeholder="email@domain.com"
              />
          </Form.Item>
          <Form.Item label="Nome" name="name">
              <Input
                  placeholder="João Santos"
              />
          </Form.Item>
          <Form.Item>
              <Button htmlType="submit" type="primary">
                  Filter
              </Button>
          </Form.Item>
        </Form>
      </Col>
      <Col lg={18} xs={24}>

        <List>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="code"
              key="code"
              title="#"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="status"
              key="status"
              title="Status"
              render={(value) => <TagField value={translateStatus(value)} />}
            />
            <Table.Column
              dataIndex="created_at"
              key="created_at"
              title="Data de Criação"
              render={(value) => <TextField value={formatDate(value)} />}
            />
            <Table.Column
              dataIndex="item"
              key="item"
              title="Curso"
              render={({ name }) => <TextField value={name} />}
            />
            <Table.Column<IOrder>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <ShowButton hideText size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>

  );
};
