import { useState } from "react";
import { QueryObserverResult } from "react-query";
import {
  IResourceComponentsProps,
  GetOneResponse,
} from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  Typography,
  useForm,
} from "@pankod/refine-antd";

import AssetUpload from "components/assetUpload";
import { IInstructorShow } from "interfaces";

const { Title } = Typography;

export const InstructorEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IInstructorShow>();
  const { isLoading, data } = queryResult as QueryObserverResult<GetOneResponse<IInstructorShow>>;

  const record = data?.data;

  if (!record) {
    return null;
  }

  return (
    <Edit isLoading={isLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Biografia"
          name="biography"
        >
          <Input />
        </Form.Item>

        <Title level={4}>Images</Title>
        <Form.Item
          label="Avatar"
          name={["images", "avatar"]}
        >
          <AssetUpload
            accept="image/png, image/jpeg"
            type="instructor"
            src={record.images.avatar}
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
