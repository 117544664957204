import { useState } from "react";
import {
  Button,
  Icons,
  Progress,
  Space,
  Table,
  TextField,
  Tooltip,
} from "@pankod/refine-antd";

import { IEnrollment } from "interfaces";
import { show } from "requests/certificates";
import { formatDate } from "services/date";

interface Props {
  enrollments: IEnrollment[];
}

interface ActionsProps {
  enrollment: IEnrollment;
}

const Actions: React.FC<ActionsProps> = ({ enrollment }) => {
  const [loading, setLoading] = useState(false);

  const { document } = enrollment.certificate_files;

  if (!document) {
    return null;
  }

  const downloadCertificate = async () => {
    setLoading(true);
    try {
      const { url } = await show({ enrollmentId: enrollment.id });

      window.open(url, "_blank");
    } catch (error) {

    }
    setLoading(false);
  }

  return (
    <div className="flex gap-2">
      <Space>
        <Button
          className="!flex items-center"
          onClick={downloadCertificate}
          loading={loading}
          icon={<Icons.DownloadOutlined />}
        >Download</Button>
      </Space>
    </div>
  )
}

const InvoiceTable: React.FC<Props> = ({ enrollments }) => (
  <Table dataSource={enrollments} rowKey="id" pagination={false} bordered scroll={{ x: true }}>
    <Table.Column
      dataIndex="course"
      key="course"
      title="Curso"
      render={({ name }) => <TextField value={name} />}
    />
    <Table.Column
      dataIndex="progress"
      key="progress"
      title="Progresso"
      render={(progress) => (
        <Tooltip title={`${progress}%`}>
          <Progress
            percent={progress}
            showInfo={false}
            strokeColor={{
              '0%': '#0322e2',
              '100%': '#08adff',
            }}
          />
        </Tooltip>
      )}
    />
    <Table.Column
      dataIndex="started_at"
      key="started_at"
      title="Início"
      render={(startedAt) => <TextField value={formatDate(startedAt, 'l')} />}
    />
    <Table.Column
      dataIndex="completed_at"
      key="completed_at"
      title="Conclusão"
      render={(completedAt) => <TextField value={completedAt && formatDate(completedAt, 'l')} />}
    />
    <Table.Column
      dataIndex="expires_at"
      key="expires_at"
      title="Expiração"
      render={(expiresAt) => <TextField value={expiresAt && formatDate(expiresAt, 'l')} />}
    />
    <Table.Column
      dataIndex="disabled_at"
      key="disabled_at"
      title="Desabilitado"
      render={(disabledAt) => <TextField value={disabledAt && formatDate(disabledAt, 'l')} />}
    />
    <Table.Column
      dataIndex="certificate_issued_at"
      key="certificate_issued_at"
      title="Emissão do certificado"
      render={(issuedAt) => <TextField value={issuedAt && formatDate(issuedAt, 'l')} />}
    />
    <Table.Column<IEnrollment>
      title="Certificado"
      dataIndex="actions"
      render={(_, enrollment) => <Actions enrollment={enrollment}/>}
    />
  </Table>
)

export default InvoiceTable;
