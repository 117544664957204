import { useState } from "react";
import {
  Button,
  Icons,
  Space,
  Table,
  TagField,
  TextField,

} from "@pankod/refine-antd";

import { IInvoice } from "interfaces";
import { download } from "requests/invoices";
import { formatDate } from "services/date";
import { translateStatus } from "services/invoice";

interface Props {
  invoices: IInvoice[];
}

interface ActionsProps {
  invoice: IInvoice;
}

const Actions: React.FC<ActionsProps> = ({ invoice }) => {
  const [loading, setLoading] = useState(false);

  if (!["issued", "cancelled"].includes(invoice.status)) {
    return null;
  }

  const downloadInvoice = async () => {
    setLoading(true);
    try {
      const { url } = await download({ invoiceId: invoice.id });

      window.open(url, "_blank");
    } catch (error) {

    }
    setLoading(false);
  }

  return (
    <div className="flex gap-2">
      <Space>
        <Button
          className="!flex items-center"
          onClick={downloadInvoice}
          loading={loading}
          icon={<Icons.DownloadOutlined />}
        >Download</Button>
      </Space>
    </div>
  )
}

const InvoiceTable: React.FC<Props> = ({ invoices }) => (
  <Table dataSource={invoices} rowKey="id" pagination={false} bordered scroll={{ x: true }}>
    <Table.Column
      dataIndex="status"
      key="status"
      title="Status"
      render={(status) => <TagField value={translateStatus(status)} />}
    />
    <Table.Column
      dataIndex="name"
      key="name"
      title="Empresa"
      render={(name) => <TextField value={name} />}
    />
    <Table.Column
    dataIndex="created_at"
    key="created_at"
    title="Data de Criação"
    render={(createdAt) => <TextField value={formatDate(createdAt, 'l')} />}
    />
    <Table.Column<IInvoice>
      title="Ações"
      dataIndex="actions"
      render={(_, invoice) => <Actions invoice={invoice}/>}
    />
  </Table>
)

export default InvoiceTable;
