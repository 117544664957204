import { useState } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  Typography,
} from "@pankod/refine-antd";

import EnrollmentTable from "components/users/enrollmentTable";
import { IUser } from "interfaces";
import { updateEmail } from "requests/users";

const { Title, Text } = Typography;

interface Props {
  user: IUser;
}

const head = (user: IUser) => ([
  { title: "Nome", text: user.name },
  { title: "Email", text: user.email },
]);

const Head: React.FC<Props> = ({ user }) => (
  <>
  <div className="flex gap-4 flex-row flex-wrap">
    {head(user).map((item) => (
      <div key={item.title} className="flex flex-col" style={{ minWidth: "100px" }}>
        <Title level={5}>{item.title}</Title>
        <Text>{item.text}</Text>
      </div>
    ))}
  </div>
  </>
);

const EmailChange: React.FC<Props> = ({ user }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async ({ email }: any) => {
    setLoading(true);

    try {
      await updateEmail({ user, email });

      window.location.reload();
    } catch {
      setLoading(false);
    }
  }

  return (
    <Form layout="vertical" onFinish={onSubmit}>
      <Form.Item
        label="Trocar email"
        name="email"
        rules={[
          {
            type: 'email',
            message: 'Preencha com um email válido!',
          },
          {
            required: true,
            message: 'Email não pode ficar vazio!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Trocar
        </Button>
      </Form.Item>
    </Form>
  )
};

const Courses: React.FC<Props> = ({ user }) => (
  <>
  <Title level={4}>Cursos</Title>
  <EnrollmentTable enrollments={user.enrollments} />
  </>
)

const Details: React.FC<Props> = ({ user }) => (
  <div className="flex gap-4 flex-col">
    <Head user={user} />
    <Divider />
    <EmailChange user={user} />
    <Divider />
    <Courses user={user} />
  </div>
);

export default Details;
