import { IResourceComponentsProps, CrudFilters, HttpError, useList } from "@pankod/refine-core";
import {
  Button,
  Col,
  EditButton,
  Form,
  FormProps,
  Input,
  List,
  Row,
  Select,
  Space,
  Table,
  TagField,
  TextField,
  useTable,
} from "@pankod/refine-antd";

import { IAssessment, IInstructor } from "interfaces";

interface IFilter {
  name: string;
  instructor: string | null;
}

interface FilterFormProps {
  searchFormProps: FormProps<IFilter>;
}

const FilterForm: React.FC<FilterFormProps> = ({ searchFormProps }) => {
  const { data: instructorsData } = useList<IInstructor>({
    resource: "instructors",
    config: {
      pagination: {
        pageSize: 100,
      },
    },
  });

  const instructors = instructorsData ? instructorsData.data : [];

  return (
    <Form layout="vertical" {...searchFormProps}>
      <Form.Item label="Nome" name="name">
          <Input placeholder="Avaliação I" />
      </Form.Item>
      <Form.Item label="Instrutor" name="instructor">
        <Select defaultValue={null}>
          <Select.Option value={null}>Todos</Select.Option>
          {instructors.map(({ id, name }) => (
            <Select.Option key={id} value={id}>{name}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
          <Button htmlType="submit" type="primary">
              Filter
          </Button>
      </Form.Item>
    </Form>
  );
}

export const AssessmentList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<IAssessment, HttpError, IFilter>({
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { name, instructor } = params;

      filters.push(
        {
          field: "name",
          operator: "eq",
          value: name,
        },
        {
          field: "instructor",
          operator: "eq",
          value: instructor,
        },

      );

      return filters;
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col lg={6} xs={24}>
        <FilterForm searchFormProps={searchFormProps} />
      </Col>
      <Col lg={18} xs={24}>

        <List>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="name"
              key="name"
              title="Nome"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="score"
              key="score"
              title="Nota mínima (%)"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column<IAssessment>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton hideText size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
