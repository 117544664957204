import { useEffect, useState } from "react";
import { QueryObserverResult } from "react-query";
import {
  GetOneResponse,
  IResourceComponentsProps,
  useInvalidate,
  useList,
  usePermissions,
} from "@pankod/refine-core";
import {
  Button,
  Create,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  useForm,
} from "@pankod/refine-antd";
import dayjs from "dayjs";

import QuestionTable from "components/assessments/questionTable";
import { IAssessmentShow, IInstructor } from "interfaces";

const { Title } = Typography;

export const AssessmentCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IAssessmentShow>();
  const { data: roles } = usePermissions();
  const { data: instructorsData } = useList<IInstructor>({
    resource: "instructors",
    config: {
      pagination: {
        pageSize: 100,
      },
    },
  });
  const invalidate = useInvalidate();

  const instructors = instructorsData ? instructorsData.data : [];

  if (!roles) {
    return null;
  }

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          required
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Nota mínima"
          name="score"
          required
        >
          <InputNumber addonAfter="%" precision={0} />
        </Form.Item>

        <Form.Item
          label="Instrutores"
          name="instructor_ids"
          required
        >
          <Select
            showSearch
            mode="multiple"
            optionFilterProp="children"
          >
          {instructors.map(({ id, name }) => (
            <Select.Option key={id} value={id}>{name}</Select.Option>
          ))}
          </Select>
        </Form.Item>
      </Form>
    </Create>
  );
};
