import { ISession } from "interfaces/auth";
import { clearRoles } from "services/roles";

export const TOKEN_KEY = "auth-token";
export const REFRESH_TOKEN_KEY = "auth-refresh-token";

export const setSession = (session: ISession) => {
  localStorage.setItem(TOKEN_KEY, session.token);
  localStorage.setItem(REFRESH_TOKEN_KEY, session.refreshToken);
};

export const clearSession = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  clearRoles();
};

export const getToken = () => (
  localStorage.getItem(TOKEN_KEY)
);

export const getRefreshToken = () => (
  localStorage.getItem(REFRESH_TOKEN_KEY)
);
