import { ISession } from 'interfaces/auth';
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
});

interface loginProps {
  username: string;
  password: string;
}

export const login = async ({ username, password }: loginProps): Promise<ISession> => {
  const { data } = await api.post('/auth/admins/login', { username, password });

  return {
    token: data.token,
    refreshToken: data.refresh_token,
  }
}

interface refreshProps {
  refreshToken: string;
}

export const refresh = async ({ refreshToken }: refreshProps): Promise<ISession> => {
  const { data } = await api.post('/auth/sessions/refresh', { token: refreshToken });

  return {
    token: data.token,
    refreshToken: data.refresh_token,
  }
}
