import { useState } from "react";
import { useCreate, useModalReturnType, useList } from "@pankod/refine-core";
import {
  Button,
  Form,
  Icons,
  Input,
  Modal,
  Progress,
  Select,
  Upload,
  UploadFile,
} from "@pankod/refine-antd";
import axios from "axios";
import convertMutateData from "components/courses/uploads/utils";
import { ITopicShow, IContent, IInstructor, IDocument, IDocumentCreate } from "interfaces";

interface Props {
  modal: useModalReturnType;
  parent: any;
  parentKind: string;
  type: any;
  onUpdate: () => void;
}

const UploadDocumentModal: React.FC<Props> = ({ modal, parent, parentKind, type, onUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [file, setFile] = useState<UploadFile | undefined>(undefined);
  const [form] = Form.useForm();
  const { mutate: documentMutate } = useCreate<IDocumentCreate>();
  const { mutate: contentMutate } = useCreate<IContent>();
  const { mutate: optionMutate } = useCreate<IDocument>();
  const { data: instructorsData } = useList<IInstructor>({
    resource: "instructors",
    config: { pagination: { pageSize: 100 } }
  });

  const instructors = instructorsData?.data || [];

  const onClose = () => {
    form.resetFields();
    setFile(undefined);
    setUploading(false);
    setUploadPercentage(0);
    setLoading(false);
    modal.close();
  }

  const onSubmit = async () => {
    setLoading(true);

    const { name, options, instructor_ids } = await form.validateFields();

    try {
      const document = await createdocument({ name, instructor_ids });
      await uploadDocument({ document });
      setUploading(false);
      await createOption({ document, option: "s3" });
      await createContent({ name, document });

      onUpdate();
      onClose();
    } catch {

    } finally {
      setUploading(false);
      setUploadPercentage(0);
      setLoading(false);
    }
  }

  const createdocument = ({ name, instructor_ids }: { name: string, instructor_ids: string[] }) => (
    new Promise<IDocumentCreate>((resolve, reject) => {
      documentMutate({
        resource: "documents",
        values: { name, instructor_ids }
      }, {
        onSuccess: ({ data }) => resolve(data),
        onError: (error) => reject(error),
      });
    })
  );

  const uploadDocument = ({ document }: { document: IDocumentCreate }) => {
    const { url } = document.upload;

    setUploading(true);

    return axios.put(url, file, {
      headers: { "Content-Type": "application/pdf" },
      onUploadProgress: ({ loaded, total }) => setUploadPercentage(Math.round(100 * loaded / total))
    })
   }

  const createOption = ({ document, option }: { document: IDocumentCreate, option: string }) => (
    new Promise<IDocument>((resolve, reject) => {
       optionMutate({
         resource: "document_options",
         values: {
           provider: option,
           document_id: document.id,
           reference: document.upload.reference,
         }
       }, {
         onSuccess: ({ data }) => resolve(data),
         onError: (error) => reject(error),
       })
     })
  );

  const createContent = ({ name, document }: { name: string, document: IDocumentCreate }) => {
    const items = parent[type] || [];

    const data = {
      name,
      kind: 'document',
      order: items.length + 1,
      reference: document.id,
      parent_type: parentKind,
      parent_id: parent.id
    }

    const mutateObject = convertMutateData(type, data);

    return new Promise<IContent>((resolve, reject) => {
      contentMutate(mutateObject, {
        onSuccess: ({ data }) => resolve(data),
        onError: (error) => reject(error),
      })
    })
  }

  return (
    <Modal
      title="Subir conteúdo em pdf"
      visible={modal.visible}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
          disabled={!file}
        >
          Upload
        </Button>
      ]}
    >
      <Form form={form} layout="vertical" preserve={false}>
        <Form.Item
          label="Nome"
          name="name"
          required
        >
          <Input />
        </Form.Item>

        <Form.Item label="Upload" name="upload" required>
          <Upload
            maxCount={1}
            accept="application/pdf"
            fileList={file ? [file] : []}
            onRemove={() => setFile(undefined)}
            beforeUpload={(newFile) => {
              setFile(newFile);

              return false;
            }}
          >
            <Button className="!flex items-center" disabled={loading}>
              <Icons.UploadOutlined /> Documento (pdf)
            </Button>
          </Upload>
          {uploading && <Progress percent={uploadPercentage} />}
        </Form.Item>

        <Form.Item
          label="Instrutores"
          name="instructor_ids"
          required
        >
          <Select
            mode="multiple"
          >
            {instructors.map(({ id, name }) => (
              <Select.Option key={id} value={id}>{name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
};

export default UploadDocumentModal;
