import { IMoney } from 'interfaces';

interface currencyIsoFormatsParams {
  currencyIso: "BRL";
  amount: string;
}

const currencyIsoFormats = ({ currencyIso, amount }: currencyIsoFormatsParams) => {
  switch (currencyIso) {
    case "BRL":
      return `R$ ${amount}`
    default:
      return amount
  }
}

export const formatMoney = ({ cents, currency_iso }: IMoney) => (
  currencyIsoFormats({
    currencyIso: currency_iso,
    amount: (cents / 100).toFixed(2)
  })
);
