import { useState } from "react";
import { useUpdate, useModalReturnType, useList } from "@pankod/refine-core";
import {
  Button,
  Checkbox,
  Form,
  Icons,
  Input,
  Modal,
  Radio,
  Select,
  Space,
} from "@pankod/refine-antd";
import axios from "axios";

import { IAssessmentShow, IAssessmentQuestion } from "interfaces";

interface Props {
  modal: useModalReturnType;
  question: IAssessmentQuestion;
  onUpdate: () => void;
}

const EditQuestionModal: React.FC<Props> = ({ modal, question, onUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { mutate } = useUpdate<IAssessmentQuestion>();

  const initialValues = {
    statement: question.statement,
    alternatives: question.alternatives.map((alternative) => ({
      id: alternative.id,
      statement: alternative.statement,
      correct: question.correct_alternative.id === alternative.id,
    })),
  };

  const onClose = () => {
    form.resetFields();
    setLoading(false);
    modal.close();
  }

  const onSubmit = async () => {
    setLoading(true);

    try {
      const { statement, alternatives } = await form.validateFields();

      await updateQuestion({ statement, alternatives });
      onUpdate();
      onClose();
    } catch {

    } finally {
      setLoading(false);
    }
  }

  const updateQuestion = ({ statement, alternatives }: { statement: string, alternatives: { id: string, statement: string, correct: boolean }[] }) => (
    new Promise<IAssessmentQuestion>((resolve, reject) => {
      mutate({
        resource: "assessment_questions",
        id: question.id,
        values: { statement, alternatives }
      }, {
        onSuccess: ({ data }) => resolve(data),
        onError: (error) => reject(error),
      });
    })
  );

  return (
    <Modal
      title="Editar questão"
      visible={modal.visible}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Salvar
        </Button>
      ]}
    >
      <Form form={form} initialValues={initialValues} layout="vertical">
        <Form.Item
          label="Enunciado"
          name="statement"
          rules={[{ required: true}]}
        >
          <Input.TextArea rows={3} />
        </Form.Item>

        <Form.List
          name="alternatives"
          rules={[
            {
              validator: async (_, alternatives) => {
                const corrects = alternatives.filter((alternative: any) => (
                  alternative?.correct
                ));

                if (corrects.length !== 1) {
                  return Promise.reject();
                }
              },
              message: "'alternatives.correct' required (1)",
            },
          ]}
        >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...field }) => (
              <div key={key} className="flex gap-4 w-full items-center">
                <Form.Item
                  {...field}
                  name={[name, 'correct']}
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
                <Form.Item
                  {...field}
                  className="grow"
                  name={[name, 'statement']}
                  rules={[{ required: true}]}
                >
                  <Input.TextArea
                    rows={1}
                  />
                </Form.Item>
                <Icons.MinusCircleOutlined className="mb-4" onClick={() => remove(name)} />
              </div>
            ))}
            <Form.ErrorList errors={errors} className="mb-4" />
            <Button type="dashed" className="!flex items-center justify-center" onClick={() => add()} block>
              <Icons.PlusOutlined />
              Alternativa
            </Button>
          </>
        )}
        </Form.List>
      </Form>
    </Modal>
  )
};

export default EditQuestionModal;
