import { useState } from "react";
import {
  useCreate,
  useDelete,
} from "@pankod/refine-core";

import {
  Button,
  Form,
  Icons,
  Input,
  Popconfirm,
  Table,
  TextField,
} from "@pankod/refine-antd";

import ContentTable from "components/courses/contentTable";
import ResourceTable from "components/courses/resourceTable";
import { ISectionShow, ITopicShow, ITopic } from "interfaces";

interface Props {
  section: ISectionShow;
  onUpdate: () => void;
}

const Actions = ({ topic, onUpdate }: { topic: ITopicShow, onUpdate: () => void }) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { mutate } = useDelete<ITopic>();

  const showPopconfirm = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    mutate({
      resource: "topics",
      id: topic.id,
    }, {
      onSuccess: () => {
        setVisible(false);
        setConfirmLoading(false);
        onUpdate();
      },
      onError: () => {
        setConfirmLoading(false);
      }
    })
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Popconfirm
      title="Deletar"
      icon={<Icons.QuestionCircleOutlined style={{ color: 'red' }} />}
      visible={visible}
      onConfirm={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ loading: confirmLoading, danger: true }}
    >
      <Button className="!flex items-center" danger onClick={showPopconfirm}>
        <Icons.CloseCircleOutlined />Deletar
      </Button>
    </Popconfirm>
  )
}

const TopicTable: React.FC<Props> = ({ section, onUpdate }) => {
  const expandedComponents = (record: any) => (
    <div className="ml-8">
      <ContentTable topic={record} onUpdate={onUpdate} />
      <ResourceTable reference={record} referenceKind='topic' onUpdate={onUpdate} />
    </div>
  )

  return (
    <Table
      dataSource={section.topics}
      rowKey="id"
      bordered
      pagination={false}
      expandedRowRender={(record) => expandedComponents(record)}
    >
      <Table.Column<ITopicShow>
        dataIndex="order"
        sorter={(a, b) => a.order - b.order}
        defaultSortOrder="ascend"
        key="order"
        title="#"
        className="w-4"
        render={(value) => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="name"
        key="name"
        title="Tópico"
        render={(value) => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="contents"
        key="contents"
        title="Conteúdos"
        className="w-4"
        render={(contents) => <TextField value={contents.length} />}
      />
      <Table.Column<ITopicShow>
        dataIndex="actions"
        key="actions"
        title="Ações"
        className="w-4"
        render={(_, topic) => <Actions topic={topic} onUpdate={onUpdate} />}
      />
    </Table>
  )
}

const CreateForm: React.FC<Props> = ({ section, onUpdate }) => {
  const [form] = Form.useForm();
  const { mutate } = useCreate<ITopicShow>();

  const onFinish = (data: any) => {
    mutate({
      resource: "topics",
      values: {
        ...data,
        section_id: section.id,
        order: section.topics.length + 1
      }
    }, { onSuccess: onUpdate });
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <div className="flex gap-4 flex-row">
        <Form.Item
          name="name"
          required
        >
          <Input placeholder="Nome"/>
        </Form.Item>

        <Button onClick={() => form.submit()}>
          Criar tópico
        </Button>
      </div>
    </Form>
  );
}

const TopicTableWrapper: React.FC<Props> = ({ section, onUpdate }) => {

  return (
    <div className="flex gap-4 flex-col">
      <TopicTable section={section} onUpdate={onUpdate} />
      <CreateForm section={section} onUpdate={onUpdate} />
    </div>
  )
}

export default TopicTableWrapper;
