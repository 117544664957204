import axios from "axios";
import { refresh } from "requests/auth";
import { getToken, getRefreshToken, setSession, clearSession } from "services/auth";

const RETRY_LIMIT = 0;
const UNAUTHORIZED = 401

const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
});

let refreshing: Promise<void> | null = null;

api.interceptors.request.use((config: any) => {
  let { headers } = config;
  const token = getToken();
  const retryCount = config.retryCount || 0;

  if (token) {
    headers = headers || {}
    headers["Authorization"] = `Bearer ${token}`
  }

  return {
    ...config,
    headers,
    retryCount,
  }
});

api.interceptors.response.use((response) => response, async (error) => {
  const { response, config } = error;
  const { retryCount } = config;

  if (response.status !== UNAUTHORIZED) {
    return Promise.reject(error);
  }

  if (retryCount > RETRY_LIMIT) {
    return Promise.reject(error);
  }

  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    clearSession();
    return Promise.reject(error);
  }

  refreshing ||= refresh({ refreshToken }).then((session) => {
    setSession(session);
  }).finally(() => {
    refreshing = null;
  });

  const retryConfig = {
    ...config,
    retryCount: retryCount + 1
  };

  try {
    await refreshing;
    return Promise.resolve(api(retryConfig));
  } catch {
    clearSession();
    return Promise.reject(error);
  }
});

export default api;
