import { IOrder } from "interfaces";

const STATUS_TRANSLATIONS = new Map([
  ["initial", "Pagamento não recebido"],
  ["approved", "Pedido Aprovado"],
  ["returned", "Pedido Reembolsado"]
]);

export const translateStatus = (status: IOrder["status"]): string => (
  STATUS_TRANSLATIONS.get(status) || status
)
