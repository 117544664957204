import { useState } from "react";
import { useDelete } from "@pankod/refine-core";
import OptionTag from "components/courses/uploads/option_tag";
import {
  Button,
  Icons,
  Popconfirm,
  Table,
  TextField,
} from "@pankod/refine-antd";

import { ITopicShow, IContent, IOption } from "interfaces";
import CreateUploadModalsGroup from 'components/courses/uploads/create_group'

interface Props {
  topic: ITopicShow;
  onUpdate: () => void;
}

const Actions = ({ content, onUpdate }: { content: IContent, onUpdate: () => void }) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { mutate } = useDelete<IContent>();

  const showPopconfirm = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    mutate({
      resource: "contents",
      id: content.id,
    }, {
      onSuccess: () => {
        setVisible(false);
        setConfirmLoading(false);
        onUpdate();
      },
      onError: () => {
        setConfirmLoading(false);
      }
    })
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Popconfirm
      title="Deletar"
      icon={<Icons.QuestionCircleOutlined style={{ color: 'red' }} />}
      visible={visible}
      onConfirm={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ loading: confirmLoading, danger: true }}
    >
      <Button className="!flex items-center" danger onClick={showPopconfirm}>
        <Icons.CloseCircleOutlined />Deletar
      </Button>
    </Popconfirm>
  )
}

const ContentTable: React.FC<Props> = ({ topic, onUpdate }) => (
  <Table
    dataSource={topic.contents}
    rowKey="id"
    bordered
    pagination={false}
  >
    <Table.Column<IContent>
      dataIndex="order"
      sorter={(a, b) => a.order - b.order}
      defaultSortOrder="ascend"
      key="order"
      title="#"
      className="w-4"
      render={(value) => <TextField value={value} />}
    />
    <Table.Column
      dataIndex="name"
      key="name"
      title="Aula"
      render={(value) => <TextField value={value} />}
    />
    <Table.Column
      dataIndex="kind"
      key="kind"
      title="Tipo"
      className="w-4"
      render={(value) => <TextField value={value} />}
    />
    <Table.Column
      dataIndex="options"
      key="options"
      title="Opções"
      className="w-4"

      render={(values) => (
        values.map((option: IOption) => (
          <OptionTag option={option} />
        ))
      )}
    />
    <Table.Column<IContent>
      dataIndex="actions"
      key="actions"
      title="Ações"
      className="w-4"
      render={(_, content) => <Actions content={content} onUpdate={onUpdate} />}
    />
  </Table>
)

const ContentTableWrapper: React.FC<Props> = ({ topic, onUpdate }) => {
  return (
    <div className="flex gap-4 flex-col">
      <ContentTable topic={topic} onUpdate={onUpdate} />
      <CreateUploadModalsGroup type="contents" parent={topic} parentKind="topic" onUpdate={onUpdate} />
    </div>
  )
};

export default ContentTableWrapper;
