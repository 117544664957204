import { IResourceComponentsProps, CrudFilters, HttpError } from "@pankod/refine-core";
import {
  Button,
  Col,
  EditButton,
  Form,
  FormProps,
  Input,
  List,
  Row,
  Space,
  Table,
  TextField,
  useTable,
} from "@pankod/refine-antd";

import { ITag } from "interfaces";

interface IFilter {
  name: string;
}

interface FilterFormProps {
  searchFormProps: FormProps<IFilter>;
}

const FilterForm: React.FC<FilterFormProps> = ({ searchFormProps }) => (
  <Form layout="vertical" {...searchFormProps}>
    <Form.Item label="Nome" name="name">
        <Input placeholder="Tecnologia" />
    </Form.Item>

    <Form.Item>
        <Button htmlType="submit" type="primary">
            Filter
        </Button>
    </Form.Item>
  </Form>
);

export const TagList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<ITag, HttpError, IFilter>({
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { name } = params;

      filters.push(
        {
          field: "name",
          operator: "eq",
          value: name,
        },
      );

      return filters;
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col lg={6} xs={24}>
        <FilterForm searchFormProps={searchFormProps} />
      </Col>
      <Col lg={18} xs={24}>

        <List>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="name"
              key="name"
              title="Nome"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="slug"
              key="slug"
              title="Slug"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column<ITag>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton hideText size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
