import { useState } from "react";
import { useCreate, useModalReturnType, useList } from "@pankod/refine-core";
import {
  Button,
  Form,
  Icons,
  Input,
  Modal,
  Progress,
  Select,
  Upload,
  UploadFile,
} from "@pankod/refine-antd";
import axios from "axios";
import convertMutateData from "components/courses/uploads/utils";
import { ITopicShow, IContent, IHyperText, IInstructor } from "interfaces";

interface Props {
  modal: useModalReturnType;
  parent: any;
  parentKind: string;
  type: string;
  onUpdate: () => void;
}

const ExternalHtmlModal: React.FC<Props> = ({ modal, parent, parentKind, type, onUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { mutate: htmlMutate } = useCreate<IHyperText>();
  const { mutate: contentMutate } = useCreate<IContent>();
  const { mutate: optionMutate } = useCreate<IHyperText>();
  const { data: instructorsData } = useList<IInstructor>({
    resource: "instructors",
    config: { pagination: { pageSize: 100 } }
  });

  const instructors = instructorsData?.data || [];

  const onClose = () => {
    form.resetFields();
    setLoading(false);
    modal.close();
  }

  const onSubmit = async () => {
    setLoading(true);

    const { name, url, instructor_ids } = await form.validateFields();

    try {
      const html = await createHtml({ name, instructor_ids });
      await createOption({ html, url });
      await createContent({ name, html });

      onUpdate();
      onClose();
    } catch {

    } finally {
      setLoading(false);
    }
  }

  const createHtml = ({ name, instructor_ids }: { name: string, instructor_ids: string[] }) => (
    new Promise<IHyperText>((resolve, reject) => {
      htmlMutate({
        resource: "hyper_texts",
        values: { name, instructor_ids }
      }, {
        onSuccess: ({ data }) => resolve(data),
        onError: (error) => reject(error),
      });
    })
  );

   const createOption = ({ html, url }: { html: IHyperText, url: string }) => (
     new Promise<IHyperText>((resolve, reject) => {
       optionMutate({
         resource: "hyper_text_options",
         values: {
           provider: "url",
           hyper_text_id: html.id,
           reference: url
         }
       }, {
         onSuccess: ({ data }) => resolve(data),
         onError: (error) => reject(error),
       })
     })
   );

  const createContent = ({ name, html }: { name: string, html: IHyperText }) => {
    const items = parent[type] || [];

    const data = {
      name,
      kind: 'hyper_text',
      reference: html.id,
      order: items.length + 1,
      parent_type: parentKind,
      parent_id: parent.id
    }

    const mutateObject = convertMutateData(type, data);

    return new Promise<IContent>((resolve, reject) => {
      contentMutate(mutateObject, {
        onSuccess: ({ data }) => resolve(data),
        onError: (error) => reject(error),
      })
    })
  }

  return (
    <Modal
      title="Subir link externo HTML"
      visible={modal.visible}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Upload
        </Button>
      ]}
    >
      <Form form={form} layout="vertical" preserve={false}>
        <Form.Item
          label="Nome"
          name="name"
          required
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="URL"
          name="url"
          required
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Instrutores"
          name="instructor_ids"
          required
        >
          <Select
            mode="multiple"
          >
            {instructors.map(({ id, name }) => (
              <Select.Option key={id} value={id}>{name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
};

export default ExternalHtmlModal;
