import { AccessControlProvider } from "@pankod/refine-core";

import { getRoles } from "services/roles";

const RESOURCES_ALLOWED = {
  master: ["orders", "order_refunds", "users", "admins", "courses", "instructors", "assessments", "tags"],
  supervisor: ["orders", "users", "admins"],
  support: ["orders", "users"],
};

const accessProvider: AccessControlProvider = {
  can: async ({ resource, action, params }) => {
    const roles = await getRoles();

    const can = roles.some((role) => (
      RESOURCES_ALLOWED[role].includes(resource)
    ));

    return { can };
  },
};

export default accessProvider;
