import { Card, Icons, Tag, Typography } from "@pankod/refine-antd";

import { IPayment, ISchemeCredit } from "interfaces";
import { formatMoney } from "services/money";
import { translateStatus, translateMotive } from "services/payment";

const { Text, Title } = Typography;

interface Props {
  payment: IPayment;
}

interface CardTitleProps {
  Icon: any;
  title: string;
}

const CardTitle: React.FC<CardTitleProps> = ({ Icon, title }) => (
  <div className="flex gap-2 items-center">
    <Icon />
    <span>{title}</span>
  </div>
)

const Boleto: React.FC<Props> = ({ payment }) => (
  <Card title={
    <CardTitle Icon={Icons.BarcodeOutlined} title="Boleto" />
  }>
  <div className="flex flex-row gap-4" >
    <div className="flex flex-col">
      <Title level={5}>Status</Title>
      <Tag>{translateStatus(payment.status)}</Tag>
    </div>

    {
      ["error", "rejected"].includes(payment.status) ? (
        <div className="flex flex-col">
          <Title level={5}>Motivo</Title>
          <Text>{translateMotive(payment.motive)}</Text>
        </div>
      ) : (
        null
      )
    }
  </div>

  </Card>
)

const Pix: React.FC<Props> = ({ payment }) => (
  <Card title={
    <CardTitle Icon={Icons.QrcodeOutlined} title="Pix" />
  }>
  <div className="flex flex-row gap-4" >
    <div className="flex flex-col">
      <Title level={5}>Status</Title>
      <Tag>{translateStatus(payment.status)}</Tag>
    </div>

    {
      ["error", "rejected"].includes(payment.status) ? (
        <div className="flex flex-col">
          <Title level={5}>Motivo</Title>
          <Text>{translateMotive(payment.motive)}</Text>
        </div>
      ) : (
        null
      )
    }
  </div>


  </Card>
)

const Credit: React.FC<Props> = ({ payment }) => {
  const { masked_number, installments } = payment.scheme as ISchemeCredit;

  const installmentAmount = {
    ...payment.amount,
    cents: payment.amount.cents / installments,
  };

  return (
    <Card title={
      <CardTitle Icon={Icons.CreditCardOutlined} title="Cartão de Crédito" />
    }>
      <div className="flex flex-row gap-4" >
        <div className="flex flex-col">
          <Title level={5}>Status</Title>
          <Tag>{translateStatus(payment.status)}</Tag>
        </div>

        {
          ["error", "rejected"].includes(payment.status) ? (
            <div className="flex flex-col">
              <Title level={5}>Motivo</Title>
              <Text>{translateMotive(payment.motive)}</Text>
            </div>
          ) : (
            null
          )
        }

        <div className="flex flex-col">
          <Title level={5}>Cartão</Title>
          <Text>{masked_number}</Text>
        </div>

        <div className="flex flex-col">
          <Title level={5}>Parcelamento</Title>
          <Text>{installments}x de {formatMoney(installmentAmount)}</Text>
        </div>
      </div>
    </Card>
  )
};

const PaymentCard: React.FC<Props> = ({ payment }) => {
  switch (payment.kind) {
    case "credit_card":
      return <Credit payment={payment} />
    case "pix":
      return <Pix payment={payment} />
    case "boleto":
      return <Boleto payment={payment} />
    default:
      return null;
  }
}

export default PaymentCard;
