import api from 'requests/api';

interface Props {
  enrollmentId: string;
}

interface Download {
  url: string;
}

export const show = async({ enrollmentId }: Props): Promise<Download> => {
  const { data } = await api.get(`/admin/enrollments/${enrollmentId}/certificate`);

  return data;
};
