import React from "react";
import routerProvider from "@pankod/refine-react-router-v6";
import { TitleProps } from "@pankod/refine-core";

import Logotype from "assets/logotype";
import Brand from "assets/brand";

const { Link } = routerProvider;

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  <Link to="/" className="flex justify-center">
    {collapsed ? (
      <Brand style={{ color: "white", width: "40px", margin: "12px 24px" }} />
    ) : (
      <Logotype style={{ color: "white", width: "200px", padding: "12px 24px" }} />
    )}
  </Link>
);
