import {
  IResourceComponentsProps,
  usePermissions,
} from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  Select,
  useForm,
} from "@pankod/refine-antd";

import { IAdmin } from "interfaces";
import { ROLE_TRANSLATIONS, allowedRoles } from "services/admin";

export const AdminCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IAdmin>();
  const { data: roles } = usePermissions();

  if (!roles) {
    return null;
  }

  const allowed = allowedRoles({ roles });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
        >
          <Input placeholder="Nome completo" />
        </Form.Item>
        <Form.Item
          label="Username"
          name="username"
        >
          <Input placeholder="username" />
        </Form.Item>
        <Form.Item
          label="Senha"
          name="password"
        >
          <Input.Password placeholder="senha" />
        </Form.Item>
        <Form.Item
            label="Funções"
            name="roles"
        >
          <Select mode="multiple">
          {Array.from(ROLE_TRANSLATIONS).map(([key, value]) => (
            <Select.Option
              key={key}
              value={key}
              disabled={!allowed.includes(key)}
            >
              {value}
            </Select.Option>
          ))}
          </Select>
        </Form.Item>
      </Form>
    </Create>
  );
};
