import * as React from "react"

const Logotype = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 1720 247" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M192.27 205.86H238.48V243.7H116.26C46.6101 243.7 0.0600586 191.46 0.0600586 128.84C0.0600586 66.55 45.6001 12.97 116.6 12.97C186.59 12.97 232.8 64.54 232.8 124.82C232.8 158.31 217.4 188.45 192.28 204.85V205.86H192.27ZM192.27 128.5C192.27 87.31 162.47 52.15 116.25 52.15C70.3701 52.15 40.9001 87.31 40.9001 128.5C40.9001 169.02 70.0301 204.52 116.25 204.52C162.8 204.52 192.27 169.02 192.27 128.5Z" fill="currentColor"></path>
    <path d="M392.2 137.54C386.51 120.79 370.1 108.07 348.33 108.07C317.86 108.07 298.77 130.84 298.77 158.97C298.77 186.76 317.86 209.87 348.33 209.87C369.43 209.87 386.51 197.48 392.2 179.73H432.72C425.35 218.24 392.54 246.71 348 246.71C294.75 246.71 260.26 207.86 260.26 158.64C260.26 109.41 294.75 70.9 348 70.9C392.54 70.9 425.36 99.03 432.72 137.54H392.2V137.54Z" fill="currentColor"></path>
    <path d="M592.79 75.59H630.97V242.02H592.79V217.91H591.12C580.07 234.65 560.65 246.71 534.53 246.71C487.65 247.38 452.49 211.21 452.49 158.97C452.49 107.4 486.65 70.56 534.53 70.9C560.32 71.23 580.07 82.62 591.12 98.69H592.79V75.59ZM592.45 158.64C592.45 130.18 572.36 107.4 542.22 107.4C511.75 107.4 491.32 129.84 491.32 158.64C491.32 187.44 511.75 209.87 542.22 209.87C572.36 209.88 592.45 186.77 592.45 158.64Z" fill="currentColor"></path>
    <path d="M789.69 75.59V114.44H755.2C729.08 114.44 711 131.85 711 160.65V242.02H672.49V75.59H709.66V103.05H711C718.37 85.64 734.44 75.59 755.87 75.59H789.69Z" fill="currentColor"></path>
    <path d="M934.36 75.59V114.44H899.87C873.75 114.44 855.67 131.85 855.67 160.65V242.02H817.16V75.59H854.33V103.05H855.67C863.04 85.64 879.11 75.59 900.54 75.59H934.36Z" fill="currentColor"></path>
    <path d="M1116.53 172.04H984.93C987.94 193.47 1006.03 210.55 1032.15 211.22C1050.9 211.55 1067.31 203.18 1074.01 189.79H1113.86C1103.14 225.96 1070.66 246.72 1031.48 246.72C976.9 246.72 945.08 206.2 945.08 158.98C945.08 109.75 980.24 70.91 1031.48 70.91C1086.73 70.9 1121.56 114.77 1116.53 172.04ZM985.6 140.56H1076.68C1071.66 119.8 1054.24 106.4 1030.8 106.4C1007.7 106.4 990.28 120.13 985.6 140.56Z" fill="currentColor"></path>
    <path d="M1186.86 0.910004V51.81H1148.35V0.910004H1186.86ZM1186.86 75.59V242.02H1148.35V75.59H1186.86Z" fill="currentColor"></path>
    <path d="M1345.92 75.59V114.44H1311.43C1285.31 114.44 1267.23 131.85 1267.23 160.65V242.02H1228.72V75.59H1265.89V103.05H1267.23C1274.6 85.64 1290.67 75.59 1312.1 75.59H1345.92Z" fill="currentColor"></path>
    <path d="M1496.95 75.59H1535.13V242.02H1496.95V217.91H1495.28C1484.23 234.65 1464.81 246.71 1438.69 246.71C1391.81 247.38 1356.65 211.21 1356.65 158.97C1356.65 107.4 1390.81 70.56 1438.69 70.9C1464.47 71.23 1484.23 82.62 1495.28 98.69H1496.95V75.59ZM1496.62 158.64C1496.62 130.18 1476.53 107.4 1446.39 107.4C1415.92 107.4 1395.49 129.84 1395.49 158.64C1395.49 187.44 1415.92 209.87 1446.39 209.87C1476.52 209.88 1496.62 186.77 1496.62 158.64Z" fill="currentColor"></path>
    <path d="M1708.26 75.59V113.1H1617.17C1610.81 113.1 1606.45 117.12 1606.45 123.48C1606.45 129.17 1610.13 132.86 1614.82 133.86L1680.46 148.26C1704.57 153.28 1719.98 171.03 1719.98 193.47C1719.98 221.6 1698.21 242.03 1669.08 242.03H1572.64V204.86H1668.75C1674.78 204.86 1679.47 200.51 1679.47 193.48C1679.47 187.79 1676.46 183.77 1670.76 182.43L1604.79 168.03C1581.35 162.67 1565.94 145.26 1565.94 123.16C1565.94 95.37 1587.37 75.61 1616.17 75.61H1708.26V75.59Z" fill="currentColor"></path>
  </svg>
)

export default Logotype;
