import { useState } from "react";
import {
  useCreate,
  useDelete,
  useModal,
} from "@pankod/refine-core";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Icons,
  Input,
  Popconfirm,
  Select,
  Table,
  TextField,
  Typography,
} from "@pankod/refine-antd";

import OfferCreateModal from "./createModal"
import OfferTable from "./table";
import { ICourseShow, IItemShow } from "interfaces";
import { formatDate } from "services/date";
import { DAYS_TRANSLATIONS, translateDays } from "services/item";

const { Title } = Typography;

interface Props {
  course: ICourseShow;
  onUpdate: () => void;
}

interface OfferButtonProps {
  item: IItemShow;
  onUpdate: () => void;
}

const ItemForm: React.FC<Props> = ({ course, onUpdate }) => {
  const [form] = Form.useForm();
  const { mutate } = useCreate<IItemShow>();

  const onFinish = (data: any) => {
    mutate({
      resource: "items",
      values: {
        kind: "course",
        reference: course.id,
        time_in_days: data.time_in_days,
      }
    }, { onSuccess: onUpdate });
  };

  const missingDays = Array.from(DAYS_TRANSLATIONS).filter(([key, value]) => (
    !course.items.map(({ time_in_days }) => time_in_days).includes(key)
  ))

  return (
    <Form form={form} onFinish={onFinish}>
      <div className="flex gap-4 flex-row">
        <Form.Item
          className="w-64"
          name="time_in_days"
          required
        >
          <Select
            placeholder="Duração"
          >
            {missingDays.map(([key, value]) => (
              <Select.Option value={key}>{value}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Button onClick={() => form.submit()}>
          Criar item
        </Button>
      </div>
    </Form>
  )
}

const OfferButton: React.FC<OfferButtonProps> = ({ item, onUpdate }) => {
  const modal = useModal();

  return (
    <>
      <Button className="w-fit" onClick={modal.show}>Criar oferta</Button>
      <OfferCreateModal item={item} onUpdate={onUpdate} modal={modal} />
    </>
  )
}

const OfferWrapper: React.FC<Props> = ({ course, onUpdate }) => (
  <div className="flex gap-4 flex-col">
    {course.items.map((item) => (
      <div className="flex gap-4 flex-col">
        <Title level={5}>{translateDays(item.time_in_days)}</Title>
        <OfferTable course={course} item={item} onUpdate={onUpdate} />
        <OfferButton item={item} onUpdate={onUpdate} />
        <Divider />
      </div>
    ))}
    <ItemForm course={course} onUpdate={onUpdate} />
  </div>
);

export default OfferWrapper;
