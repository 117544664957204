import { Timeline } from "@pankod/refine-antd";

import { IOrder } from "interfaces";
import { formatDate } from "services/date";

interface Props {
  order: IOrder;
}

const OrderTimeline: React.FC<Props> = ({ order }) => (
  <Timeline
    pending={(
      order.returned_at || order.fulfilled_at
    ) ? (
      false
    ) : (
      order.approved_at && "Aguardando Liberação"
    ) || (
      order.created_at && "Aguardando Pagamento"
    )}
  >
    {order.created_at && (
      <Timeline.Item>Criado: {formatDate(order.created_at)}</Timeline.Item>
    )}
    {order.approved_at && (
      <Timeline.Item>Aprovado: {formatDate(order.approved_at)}</Timeline.Item>
    )}
    {order.fulfilled_at && (
      <Timeline.Item>Liberado: {formatDate(order.fulfilled_at)}</Timeline.Item>
    )}
    {order.returned_at && (
      <Timeline.Item>Reembolsado: {formatDate(order.returned_at)}</Timeline.Item>
    )}
  </Timeline>
);

export default OrderTimeline;
