import { useState } from "react";
import { QueryObserverResult } from "react-query";
import {
  IResourceComponentsProps,
  GetOneResponse,
} from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  useForm,
} from "@pankod/refine-antd";

import { ITag } from "interfaces";

export const TagEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<ITag>();
  const { isLoading, data } = queryResult as QueryObserverResult<GetOneResponse<ITag>>;

  const record = data?.data;

  if (!record) {
    return null;
  }

  return (
    <Edit isLoading={isLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Slug"
          name="slug"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Descrição"
          name="description"
        >
          <Input.TextArea rows={3} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
