import { IPayment } from "interfaces";

const STATUS_TRANSLATIONS = new Map([
  ["initial", "Processando"],
  ["processing", "Processando"],
  ["approved", "Aprovado"],
  ["rejected", "Rejeitado"],
  ["expired", "Expirado"],
  ["error", "Erro"],
]);

const MOTIVE_TRANSLATIONS = new Map([
  ["data", "Dados inválidos"],
  ["risk", "Risco de fraude"],
  ["blocked", "Bloqueado"],
  ["other", "Outro"],
  ["balance", "Limite insuficiente"],
])

export const translateStatus = (status: IPayment["status"]): string => (
  STATUS_TRANSLATIONS.get(status) || status
)

export const translateMotive = (motive: IPayment["motive"]): string => (
  MOTIVE_TRANSLATIONS.get(motive) || motive
)
