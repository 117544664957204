import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { Show } from "@pankod/refine-antd";

import Details from "components/users/details";
import { IUser} from "interfaces";

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IUser>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      {record && <Details user={record} />}
    </Show>
  );
};
