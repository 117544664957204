import { ICourseShow } from "interfaces";

export const TEMPLATE_TRANSLATIONS = new Map([
  [null, "Não definido"],
  ["estacio", "Estácio"],
  ["estacio_rodolfo", "Estacio (Ass: Rodolfo)"],
  ["ibmec", "IBMEC"],
  ["xpeed", "XPEED"],
  ["anitta", "Anitta"],
  ["metaverse", "Metaverse Academy"],
  ["wyden", "Wyden"],
]);

export const translateTemplate = (status: ICourseShow["certificate_template_key"]): string => (
  TEMPLATE_TRANSLATIONS.get(status) || status || ""
)
