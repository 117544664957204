import { useState } from "react";
import {
  IResourceComponentsProps,
} from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  useForm,
} from "@pankod/refine-antd";

import AssetUpload from "components/assetUpload";
import { ITag } from "interfaces";

export const TagCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<ITag>();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Slug"
          name="slug"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Descrição"
          name="description"
        >
          <Input.TextArea rows={3} />
        </Form.Item>
      </Form>
    </Create>
  );
};
