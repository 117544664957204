import { IResourceComponentsProps, useShow, useInvalidate } from "@pankod/refine-core";
import { Show } from "@pankod/refine-antd";

import OrderDetails from "components/orders/details";
import { IOrder } from "interfaces";

export const OrderShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IOrder>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const invalidate = useInvalidate();

  const onUpdate = () => {
    invalidate({
      resource: "orders",
      invalidates: ["detail"],
      id: record?.id,
    });
  };

  return (
    <Show isLoading={isLoading}>
      {record && <OrderDetails order={record} onUpdate={onUpdate} />}
    </Show>
  );
};
